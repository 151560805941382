import React from "react";
import { Link } from "react-router-dom";
import logoImg from "modules/ui/images/logo.png";
import logoLightImg from "modules/ui/images/logo-light.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-24",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >

     {img ? (
        <img
          className={`block ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt=""
        />
      ) : (
        "Logo Here"
      )}

      {imgLight && (
        <img
          className="hidden dark:block"
          src={imgLight}
          alt=""
        />
      )}
    </Link>
  );
};

export default Logo;
