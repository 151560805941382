import React, { FC } from "react";
import BookingForm from "./BookingForm";

export interface HeroSearchFormProps {
  className?: string;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 z-10 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      <BookingForm />
    </div>
  );
};

export default HeroSearchForm;
