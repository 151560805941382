import React, {FC, useState} from "react";
import HotelImage from "../../modules/hotels/types/HotelImage";
import NcImage from "../../modules/ui/shared/NcImage/NcImage";
import ModalImagesGallery from "./ModalImagesGallery";

export interface HotelImagesGalleryProps {
    hotelId: number;
    hotelImages: HotelImage[];
}

const HotelImagesGallery: FC<HotelImagesGalleryProps> = ({
    hotelId,
    hotelImages,
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);

    const containerPaddingClassName = hotelImages.length > 1 ? "2xl:px-14" : "";
    const gridSizeClassName = hotelImages.length > 1 ? "" : "lg:w-3/5 xl:w-2/3 lg:pr-20";
    const gridImageSpanClassName = hotelImages.length > 1 ? "" : "sm:col-span-4";

    return (
        <>
            <header className={`container rounded-md sm:rounded-xl ${containerPaddingClassName}`}>
                <div className={`relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 ${gridSizeClassName}`}>
                    <div
                        className={`col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer ${gridImageSpanClassName}`}
                        onClick={() => handleOpenModal(0)}
                    >
                        <NcImage
                            containerClassName="w-full h-full"
                            className="object-cover w-full h-full rounded-md sm:rounded-xl"
                            src={(process.env.REACT_APP_API_HOTEL_STORAGE as string) + "/" + hotelId + "/" + hotelImages[0].file}
                        />
                        <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                    </div>
                    {hotelImages.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                        <div
                            key={index}
                            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                                index >= 3 ? "hidden sm:block" : ""
                            }`}
                        >
                            <NcImage
                                containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                src={(process.env.REACT_APP_API_HOTEL_STORAGE as string) + "/" + hotelId + "/" + item.file || ""}
                            />

                            {/* OVERLAY */}
                            <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                onClick={() => handleOpenModal(index + 1)}
                            />
                        </div>
                    ))}

                    <div
                        className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                        onClick={() => handleOpenModal(0)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                            />
                        </svg>
                        <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
                    </div>
                </div>
            </header>
            {/* MODAL PHOTOS */}
            <ModalImagesGallery
                hotelImages={hotelImages}
                hotelId={hotelId}
                isOpen={isOpen}
                onClose={handleCloseModal}
                initFocus={openFocusIndex}
                uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
            />
        </>
    );
};

export default HotelImagesGallery;
