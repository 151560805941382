import React, {FC} from "react";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import NcModal from "../../modules/ui/shared/NcModal/NcModal";
import ButtonSecondary from "../../modules/ui/shared/Button/ButtonSecondary";

interface BookingErrorModalProps {
    isOpen: boolean,
    onClose: () => void;
    error?: string;
}

const BookingErrorModal: FC<BookingErrorModalProps> = ({
    isOpen,
    onClose,
    error
 }) => {
    const t = useTranslate();

    const modalTitle = t("booking.errorWhenMakingTheBookingHeading")

    const modalContent = (
        <div className="text-center">

            <i className="text-9xl las la-times la-fw text-red-500 mb-1"/>

            {error && <p className="font-bold mb-1 text-2xl">{error}</p>}

            <p className="text-1xl">
                {t("booking.errorWhenMakingTheBookingBody")}
            </p>

            <div className="flex justify-between pt-8">
                <ButtonSecondary onClick={onClose}>
                    {t("common.actions.back")}
                </ButtonSecondary>
            </div>
        </div>
    );

    return (
        <NcModal
            contentExtraClass="max-w-screen-sm"
            modalTitle={modalTitle}
            renderContent={() => modalContent}
            renderTrigger={() => null}
            isOpenProp={isOpen}
            onCloseModal={onClose}
        />
    )
}

export default BookingErrorModal;
