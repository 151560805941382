import { useCallback } from "react";
import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import RoomAvailability from "../types/RoomAvailability";

export default function useCheckNumberOfHotelsOnBasket () {
    const client = useAPIClient();

    return useCallback((eventHotelRoomId: number,
                        prebookingId: any,
                        eventHotelId: number) => {
        return client.get<RoomAvailability>(`prebooking-room/` + eventHotelRoomId + `/hotel-basket-availability`, {
            prebookingId: prebookingId,
            eventHotelId: eventHotelId
        });
    }, [client])
}