import React, { SelectHTMLAttributes } from "react";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  backgroundClass?: string;
  sizeClass?: string;
  isInvalid?: boolean;
}

const Select= React.forwardRef<HTMLSelectElement, SelectProps>(({
  className = "",
  backgroundClass = "bg-white dark:bg-neutral-900",
  sizeClass = "h-11",
  isInvalid = false,
  children,
  ...args
}, ref) => {
  const borderClass = isInvalid ?
      "border-red-400 focus:border-red-500 dark:border-red-800"
      : "border-neutral-200 focus:border-primary-300 dark:border-neutral-700";

  return (
    <select
      ref={ref}
      className={`nc-Select ${sizeClass} ${backgroundClass} block w-full text-sm rounded-2xl focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 ${borderClass} ${className}`}
      {...args}
    >
      {children}
    </select>
  );
});

export default Select;
