import React, {FC} from "react";
import Hotel from "../../modules/hotels/types/Hotel";
import GoogleMapReact from "google-map-react";
import LocationMarker from "../../modules/ui/components/AnyReactComponent/LocationMarker";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";

export interface HotelLocationCardProps {
    hotel: Hotel;
}

const HotelLocationCard: FC<HotelLocationCardProps> = ({
    hotel,
}) => {
    const t = useTranslate();

    return (
        <div className="listingSection__wrap !mt-5">
            {/* HEADING */}
            <div>
                <h2 className="text-2xl font-semibold">{t("hotelDetail.location")}</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {hotel.address}
                </span>
            </div>

            {/* MAP */}
            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string),
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        defaultZoom={15}
                        defaultCenter={{
                            lat: hotel.lat,
                            lng: hotel.lng,
                        }}
                    >
                        <LocationMarker lat={hotel.lat} lng={hotel.lng} />
                    </GoogleMapReact>
                </div>
            </div>
        </div>
    );
};

export default HotelLocationCard;
