import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import EventCity from "../types/EventCity";

type Output = EventCity[];

export default function useEventCitiesByEventGet (
    eventId: number,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["event-cities", eventId],
        () => {
            return client.get<any>(`event-cities-by-event`, {
                    "eventId": eventId
            });
        },
        options
    );
}