import SectionHero from "modules/ui/components/SectionHero/SectionHero";
import React, { useEffect } from "react";
import BgGlassmorphism from "modules/ui/components/BgGlassmorphism/BgGlassmorphism";
import PublicLayout from "../../modules/ui/components/PublicLayout";
import { useParams } from "react-router-dom";
import CityEventDescription from "./CityEventDescription";
import useEventCityGet from "../../modules/eventCities/hooks/useEventCityGet";
import HotelsMapGridSection from "./MainEventCitySection/HotelsMapGridSection";
import useBookingSearchContext from "../../modules/booking/hooks/useBookingSearchContext";
import Loader from "modules/ui/shared/Loader/Loader";
import moment from "moment";
import SectionSearchForm from "./SectionSearchForm";

function PageCityEvent() {
    const { id } = useParams();

    const {
        city: bookSearchCity,
        setCity: setBookSearchCity,
        dates: bookDates,
        setDates: setBookDates,
        cities: bookCities
    } = useBookingSearchContext();

    const { data: eventCity } = useEventCityGet(id);

    const bookFrom = bookSearchCity?.id === eventCity?.id ? bookDates.startDate?.format("DD/MM/YYYY") || null : eventCity?.startEventDate;
    const bookUntil = bookSearchCity?.id === eventCity?.id ? bookDates.endDate?.format("DD/MM/YYYY") || null : eventCity?.endEventDate;

    useEffect(() => {
        if (bookCities && eventCity && bookSearchCity?.id !== eventCity.id) {
            const newBookCity = bookCities.find((bookCity) => bookCity.id === eventCity.id) || null;
            setBookSearchCity(newBookCity);
            setBookDates({
                startDate: newBookCity?.event_start_date ? moment(newBookCity.event_start_date) : null,
                endDate: newBookCity?.event_end_date ? moment(newBookCity?.event_end_date) : null,
            });
        }
    }, [eventCity, bookSearchCity, setBookSearchCity, setBookDates, bookCities]);

    return (
        <PublicLayout>
            <div className="nc-ListingStayMapPage relative">
                {/* GLASSMOPHIN */}
                <BgGlassmorphism/>

                {/* SECTION HERO */}
                {eventCity ?
                    <SectionHero mainImage={process.env.REACT_APP_API_CITY_BANNERS_STORAGE + eventCity.banner}
                                 imageContainerClassName="lg:h-[350px] overflow-hidden"
                                 showBookForm={false} />
                    : <Loader centerHorizontally />}

                <SectionSearchForm />

                <div className="container relative space-y-10 my-10 lg:space-y-12 lg:my-12">
                    {/* EVENT DESCRIPTION */}
                    <CityEventDescription cityEventId={id}/>
                </div>
                {/* SECTION */}
                <div className="container pb-24 lg:pb-28 2xl:px-10 xl:pr-0 xl:max-w-none">
                    {eventCity ?
                        <HotelsMapGridSection eventCity={eventCity} bookFrom={bookFrom} bookUntil={bookUntil}/>
                        : <Loader centerHorizontally />}
                </div>

            </div>
        </PublicLayout>
    );
}

export default PageCityEvent;
