import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import HotelCategory from "../types/HotelCategory";

type Output = HotelCategory[];

export default function useHotelCategoriesGetAll (
    hotelId: any,
    languageId: number,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["hotel-categories-by-hotel", hotelId, languageId],
        () => {
            return client.get<any>(`hotel-categories/hotel/`+hotelId, {
                "languageId": languageId
            });
        },
        options
    );
}