import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useDataSave, {DataSaveType} from "../../core/services/query/hooks/useDataSave";
import useCacheRemove from "../../core/services/query/hooks/useCacheRemove";

type GuestByRoom = {
    name: string,
    surname: string
}

type Guests = {
    eventHotelRoom: number,
    guestsByRooms: GuestByRoom[],
    arrivalHour: string,
    comments: string,
    prebookingRoomId: number
}

export interface BookSaveInput {
    prebookingInternalId: string | null;
    name: string,
    surname: string,
    email: string,
    dialCode?: string | null,
    telephone: string,
    iso2?: string | null,
    guests: Guests[],
    arrivalHour?: string,
    conditions: boolean,
    cardName: string | null,
    cardNumber: string | null,
    cardDate: string | null,
}

export default function useBookSave (): DataSaveType<BookSaveInput> {
    const client = useAPIClient();
    const removeCache = useCacheRemove();

    return useDataSave<BookSaveInput>(
        input => client.post("booking-from-prebooking", input),
        {
            onSuccess: () => {
                removeCache("basket-count");
                removeCache("basket-items")
            },
        }
    );
}
