export const CATEGORY_ICON_TO_LAS_ICON: any = {
    1: "la-parking",
    2: "la-wifi",
    3: "la-baby-carriage",
    4: "la-swimmer",
    5: "la-swimming-pool",
    6: "la-concierge-bell",
    7: "la-clock",
    8: "la-spa",
    9: "la-bath",
    10: "la-shower",
    11: "la-smoking-ban",
    12: "la-umbrella-beach",
    13: "la-city",
    14: "la-tree",
    15: "la-dumbbell",
    16: "la-hot-tub",
    17: "la-tv",
    18: "la-bed",
    19: "la-users",
    20: "la-cocktail",
    21: "la-coffee",
    22: "la-utensils",
    23: "la-snowflake",
    24: "la-wheelchair",
    25: "la-plane-departure",
    26: "la-golf-ball",
    27: "la-dice-five",
    28: "la-dog",
    29: "la-train"
}
