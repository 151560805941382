import Event from "../types/Event";
import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";

export default function useEventGet (
    id: number,
    options?: CacheQueryOpts
): CacheQueryOutput<Event> {
    const client = useAPIClient();
    return useCacheQuery<Event>(
        ["event", id],
        () => {
            return client.get<any>(`events/${id}`);
        },
        options
    );
}