import React from "react";

interface CookiesPolicyTextProps {
    content: string;
}

const CookiesPolicyText = ({
    content,
}: CookiesPolicyTextProps) => {
    return <span dangerouslySetInnerHTML={{__html: content}}/>;
}

export default CookiesPolicyText;
