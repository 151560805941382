import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import CityName from "../../cities/types/CityName";

type Output = CityName[];

export default function (
    customerId: number,
    languageId: number,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["event-cities-by-active-events", customerId, languageId],
        () => {
            return client.get<any>(`event-cities-by-active-events`, {
                "customerId": customerId,
                "languageId": languageId
            });
        },
        options
    );
}