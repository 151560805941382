import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "../../../modules/ui/shared/NcImage/NcImage";

export interface CityCardProps {
    className?: string;
    cityName?: string;
    cityImage: string;
    eventCityId: number;
}

const CityCard: FC<CityCardProps> = ({
    className = "",
    cityName,
    cityImage,
    eventCityId
}) => {
    return (
        <Link
            to={"/event-city/" + eventCityId}
            className={`nc-CardCategoryBox1 relative flex items-center p-2 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
            data-nc-id="CardCategoryBox1"
        >
            <div className="relative flex-shrink-0 w-10 h-10 rounded-full overflow-hidden">
                <NcImage src={cityImage} containerClassName="absolute inset-0" />
            </div>
            <div className="ml-4 flex-grow overflow-hidden">
                <h2 className="text-base font-medium">
                    <span className="line-clamp-1">{cityName}</span>
                </h2>
            </div>
        </Link>
    );
};

export default CityCard;
