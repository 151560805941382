import React from "react";
import BasketItem from "modules/booking/types/BasketItem";
import InputField from "modules/ui/components/InputField/InputField";
import useTranslate from "modules/core/languages/hooks/useTranslate";
import {StarIcon} from "@heroicons/react/24/solid";
import TextAreaField from "../../modules/ui/components/TextAreaField/TextAreaField";
import SelectField from "../../modules/ui/components/SelectField/SelectField";

interface RoomDetailsProps {
    form: any,
    basketItem: BasketItem,
    index: number
}

const RoomDetail: React.FC<RoomDetailsProps> = ({ form, basketItem, index }) => {
    const t = useTranslate();

    const { errors } = form.formState;

    const getGuestByRoomError = (roomIndex: number, guestIndex: number, field: string) => {
        const guest = errors.guests?.at(roomIndex)?.guestsByRooms?.at(guestIndex);

        return guest ? guest[field]?.message : undefined;
    }

    return (
        <div className="bg-gray-100 rounded p-3 mb-4">
            <input type="hidden" {...form.register(`guests[${index}][eventHotelRoom]`)} defaultValue={basketItem.room_hotel_event_id} />
            <input type="hidden" {...form.register(`guests[${index}][prebookingRoomId]`)} defaultValue={basketItem.item_id} />
            <div className="flex items-end">
                {[...Array(basketItem.hotel_stars)].map((value, index) => <StarIcon className="w-[18px] h-[18px] text-orange-500" key={index} />)}
            </div>
            <h4 className="text-xl font-semibold mb-1">{basketItem.hotel_name}</h4>
            <h5 className="text-xl text-gray-500 font-semibold mb-2">{basketItem.room_name}</h5>

            <div className="lg:flex lg:space-x-5">
                <div className="lg:w-1/2 mb-2">
                    <SelectField
                        label={`${t("booking.checkInTime")} (${basketItem.book_from_to_front})`}
                        defaultValue=""
                        {...form.register(`guests[${index}][arrivalHour]`)}
                        error={errors.guests?.at(index)?.arrivalHour?.message}
                    >
                        <option value="">{t("booking.iDontKnow")}</option>
                        <option value="00:00">00:00</option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                    </SelectField>
                </div>
            </div>

            {[...Array(basketItem.occupancy)].map((v, guestIndex) => (
                <React.Fragment key={guestIndex}>
                    <h6 className="text-lg font-semibold mb-1">{t("booking.guestN", { number: guestIndex + 1 })}{guestIndex >= basketItem.occupancy - basketItem.childrens ? ` (${t("booking.child")})` : null}</h6>
                    <div className="lg:flex lg:space-x-5">
                        <div className="lg:w-1/2 mb-2">
                            <InputField
                                label={t("booking.name")}
                                type="text"
                                defaultValue=""
                                {...form.register(`guests[${index}][guestsByRooms][${guestIndex}][name]`, { required: t("common.feedback.field_required") })}
                                error={getGuestByRoomError(index, guestIndex, 'name')}
                            />
                        </div>
                        <div className="lg:w-1/2 mb-2">
                            <InputField
                                label={t("booking.surname")}
                                type="text"
                                defaultValue=""
                                {...form.register(`guests[${index}][guestsByRooms][${guestIndex}][surname]`, { required: t("common.feedback.field_required") })}
                                error={getGuestByRoomError(index, guestIndex, 'surname')}
                            />
                        </div>
                    </div>
                </React.Fragment>)
            )}

            <TextAreaField
                label={t("booking.comments")}
                defaultValue=""
                {...form.register(`guests[${index}][comments]`)}
                error={errors.guests?.at(index)?.comments?.message}
            />
        </div>
    );
}

export default RoomDetail;
