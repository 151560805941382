import React, {FC} from "react";
import { Link } from "react-router-dom";
import NcImage from "../../../modules/ui/shared/NcImage/NcImage";
import EventCity from "../../../modules/eventCities/types/EventCity";
import useCityTranslationByCityAndLanguageGet
    from "../../../modules/cities/hooks/useCityTranslationByCityAndLanguageGet";
import useCustomerContext from "../../../modules/customers/hooks/useCustomerContext";
import useTranslate from "../../../modules/core/languages/hooks/useTranslate";

export interface MainCityCardProps {
    eventCity: EventCity;
}

const MainCityCard: FC<MainCityCardProps> = ({
    eventCity,
}) => {
    const t = useTranslate();
    const { languageId } = useCustomerContext();
    const cityTranslationQuery = useCityTranslationByCityAndLanguageGet(eventCity.city_id, languageId);
    const { data: cityTranslation } = cityTranslationQuery;

    const renderContent = () => {
        return (
            <div className={`grid grid-cols-3 p-4`}>
                <div className="space-y-2">
                    <span className="text-sm text-neutral-500 dark:text-neutral-400">
                        {t("cityEventMainCard.from")} {eventCity.startEventDate} {t("cityEventMainCard.to")} {eventCity.endEventDate}
                    </span>
                    <div className="flex items-center space-x-2">
                        <h2 className={` font-medium capitalize text-lg`}>
                            <span className="line-clamp-1">{cityTranslation?.name}</span>
                        </h2>
                    </div>
                </div>
                <div className="space-y-0"></div>
                <div className="space-y-0">
                    <Link to={"/event-city/" + eventCity.id}>
                        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                            <i className="las la-bed la-2x"></i>
                            <span className="">{t("cityEventMainCard.hotels")}</span>
                        </div>
                    </Link>
                    {eventCity.transfer && (
                        <a target="_blank" href={eventCity.transfer}>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                                <i className="las la-car la-2x"></i>
                                <span className="">{t("cityEventMainCard.transferService")}</span>
                            </div>
                        </a>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
            data-nc-id="StayCard"
        >   <Link to={"/event-city/" + eventCity.id}>
                <NcImage
                    src={process.env.REACT_APP_API_CITY_BANNERS_STORAGE + eventCity.banner}
                    className="object-cover w-full h-full rounded-2xl"
                />
            </Link>
            {renderContent()}

        </div>
    );
};

export default MainCityCard;
