import React from "react";
import Input, { InputProps } from "modules/ui/shared/Input/Input";
import Label from "modules/ui/components/Label/Label";
import InputError from "modules/ui/shared/InputError/InputError";

export interface InputFieldProps extends InputProps {
    label?: React.ReactNode;
    error?: string;
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
    (
        {
            label,
            error,
            ...inputProps
        },
        ref
    ) => {
        return (
            <div className="space-y-1 overflow-hidden">
                {label && <Label isInvalid={!!error}>{label}</Label>}
                <Input ref={ref} isInvalid={!!error} {...inputProps} />
                {error && <InputError>{error}</InputError>}
            </div>
        );
    }
);

export default InputField;
