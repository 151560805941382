import React, {TextareaHTMLAttributes} from "react";

export interface TextareaProps
    extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    isInvalid?: boolean,
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({className = "", isInvalid = false, children, ...args}, ref) => {
        const borderClass = isInvalid ?
            "border-red-400 focus:border-red-500 dark:border-red-800"
            : "border-neutral-200 focus:border-primary-300 dark:border-neutral-700";

        return (
            <textarea
                ref={ref}
                className={`block w-full text-sm rounded-2xl focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${borderClass} ${className}`}
                rows={4}
                {...args}
            >
        {children}
      </textarea>
        );
    }
);

export default Textarea;
