import React, { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import CityName from "../../../cities/types/CityName";
import ClearDataButton from "./ClearDataButton";

export interface FormCityInputProps {
    cityEvents: CityName[];
    defaultValue: CityName | null;
    onChange?: (value: string) => void;
    onInputDone?: (city: CityName) => void;
    placeHolder?: string;
    desc?: string;
    autoFocus?: boolean;
}

const CityInput: FC<FormCityInputProps> = ({
    cityEvents,
    defaultValue,
    autoFocus = false,
    onChange,
    onInputDone,
    placeHolder = "Event",
    desc = "Select the event to book",
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [value, setValue] = useState(defaultValue);
    const [showPopover, setShowPopover] = useState(autoFocus);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        setShowPopover(autoFocus);
    }, [autoFocus]);

    useEffect(() => {
        if (eventClickOutsideDiv) {
            document.removeEventListener("click", eventClickOutsideDiv);
        }
        showPopover && document.addEventListener("click", eventClickOutsideDiv);
        return () => {
            document.removeEventListener("click", eventClickOutsideDiv);
        };
    }, [showPopover]);

    useEffect(() => {
        if (showPopover && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showPopover]);

    const eventClickOutsideDiv = (event: MouseEvent) => {
        if (!containerRef.current) return;
        // CLICK IN_SIDE
        if (!showPopover || containerRef.current.contains(event.target as Node)) {
            return;
        }
        // CLICK OUT_SIDE
        setShowPopover(false);
    };

    const handleSelectEvent = (item: CityName) => {
        setValue(item);
        onInputDone && onInputDone(item);
        setShowPopover(false);
    };

    const renderRecentSearches = () => {
        return (
            <>
                <div className="mt-2">
                    {cityEvents ? cityEvents.map((item: CityName) => (
                        <span
                            onClick={() => handleSelectEvent(item)}
                            key={item.id}
                            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                        >
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
                    )) : null}


                </div>
            </>
        );
    };

    const renderSearchValue = () => {
        return (
            <>
                {cityEvents ? cityEvents.map((item: CityName) => (
                    <span
                        onClick={() => handleSelectEvent(item)}
                        key={item.id}
                        className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                    >
            <span className="block text-neutral-400">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 sm:h-6 sm:w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.name}
            </span>
          </span>
                )) : null}
            </>
        );
    };

    return (
        <div className={`relative flex flex-[1.5]`} ref={containerRef}>
            <div
                onClick={() => setShowPopover(true)}
                className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
                    showPopover ? "nc-hero-field-focused" : ""
                }`}
            >
                <div className="text-neutral-300 dark:text-neutral-400">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="nc-icon-field"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                    </svg>
                </div>
                <div className="flex-grow">
                    <input
                        className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                        placeholder={placeHolder}
                        value={value?.name || ''}
                        autoFocus={showPopover}
                        onChange={(e) => {
                            onChange && onChange(e.currentTarget.value);
                        }}
                        ref={inputRef}
                    />
                    <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
                    {value && showPopover && (
                        <ClearDataButton
                            onClick={() => {
                                setValue(null);
                                onChange && onChange("");
                            }}
                        />
                    )}
                </div>
            </div>
            {showPopover && (
                <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                    {value ? renderSearchValue() : renderRecentSearches()}
                </div>
            )}
        </div>
    );
};

export default CityInput;
