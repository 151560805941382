import React from "react";
import Checkbox , { CheckboxProps } from "../../shared/Checkbox/Checkbox";
import InputError from "modules/ui/shared/InputError/InputError";

export interface CheckboxFieldProps extends CheckboxProps {
    error?: string;
}

const CheckboxField = React.forwardRef<HTMLInputElement, CheckboxFieldProps>(
    (
        {
            error,
            ...inputProps
        },
        ref
    ) => {
        return (
            <div className="space-y-1">
                <Checkbox ref={ref} isInvalid={!!error} {...inputProps} />
                {error && <InputError>{error}</InputError>}
            </div>
        );
    }
);

export default CheckboxField;
