import axios, { AxiosRequestConfig } from "axios";

type ErrorHandler = (
    response: { statusCode: number; content?: any },
    error: Error
) => void;

export default class APIClient {
    private token: string | undefined;
    private errorHandler: ErrorHandler | undefined;

    constructor(token?: string) {
        this.token = token;
    }

    public setAuthToken(token: string): void {
        this.token = token;
    }

    public removeAuthToken(): void {
        this.token = undefined;
    }

    public onError(handler: ErrorHandler): void {
        this.errorHandler = handler;
    }

    public get<T = unknown>(endpoint: string, params?: unknown): Promise<T> {
        return this.makeRequest("get", endpoint, params);
    }

    public post<T = unknown>(endpoint: string, data?: unknown): Promise<T> {
        return this.makeRequest("post", endpoint, data);
    }

    public put<T = unknown>(endpoint: string, data?: unknown): Promise<T> {
        return this.makeRequest("put", endpoint, data);
    }

    public delete<T = unknown>(endpoint: string, data?: unknown): Promise<T> {
        return this.makeRequest("delete", endpoint, data);
    }

    public download(endpoint: string, params?: unknown): Promise<void> {
        return this.makeRequest("get", endpoint, params, true);
    }

    private makeRequest<T = unknown>(
        method: "get" | "post" | "put" | "delete",
        endpoint: string,
        data?: any,
        isDownload = false
    ): Promise<T> {
        const options: AxiosRequestConfig = {
            method,
            url: APIClient.composeUrl(endpoint),
            responseType: isDownload ? "arraybuffer" : "json",
        };

        const headers: Record<string, string> = {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        };

        if (this.token) {
            headers.authorization = `Bearer ${this.token}`;
        }

        options.headers = headers;

        if (data) {
            if (method === "get") {
                options.params = data;
            } else {
                if (Object.values(data).find(value => value instanceof File)) {
                    options.data = new FormData();
                    Object.keys(data).forEach(key => {
                        APIClient.fillFormData(options.data, key, data[key]);
                    });
                } else {
                    options.data = data;
                }
            }
        }

        return axios(options)
            .then(response => {
                if (!isDownload) {
                    return response.data;
                } else {
                    const contentType = response.headers["content-type"];
                    const disposition = response.headers["content-disposition"];
                    let filename = disposition
                        .split("filename=")[1]
                        .split(";")[0];
                    filename = filename
                        .replace(/["']/g, "")
                        .substring(0, filename.length);

                    const a = document.createElement("a");
                    const blob = new Blob([response.data], {
                        type: contentType,
                    });
                    const url = window.URL.createObjectURL(blob);

                    a.href = url;
                    a.download = filename;
                    document.body.append(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            })
            .catch(e => {
                if (e.response) {
                    // The request was made and the server responded with a status code,
                    // then return the error message from the API.
                    const error = new Error(
                        e.response.data?.error ||
                            e.response.data?.message ||
                            e.response.data?.detail ||
                            "Unexpected error"
                    );

                    this.handleError(e, error);
                } else {
                    //No response from the API
                    this.handleError(e);
                }
            });
    }

    private handleError(e: any, handledError?: Error): void {
        if (this.errorHandler !== undefined) {
            this.errorHandler(
                {
                    statusCode: e.response?.status || 500,
                    content: e.response?.data,
                },
                handledError || e
            );
        } else {
            throw handledError || e;
        }
    }

    private static composeUrl(endpoint: string): string {
        return process.env.REACT_APP_API_BASE_URL + endpoint;
    }

    private static fillFormData(
        formData: FormData,
        name: string,
        values: any
    ): void {
        if (values instanceof File) {
            formData.append(name, values);
        } else if (typeof values === "object") {
            if (values === null) return;

            Object.keys(values).forEach(key => {
                const newKey = `${name}[${key}]`;
                if (typeof values[key] === "object") {
                    this.fillFormData(formData, newKey, values[key]);
                } else {
                    formData.append(
                        newKey,
                        this.composeFormDataValue(values[key])
                    );
                }
            });
        } else {
            formData.append(name, this.composeFormDataValue(values));
        }
    }

    private static composeFormDataValue(value: any) {
        if (typeof value === "boolean") {
            return value ? "1" : "0";
        }

        if (typeof value === "number") {
            return value + "";
        }

        return value;
    }
}
