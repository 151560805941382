import { useCallback } from "react";
import useAPIClient from "../../core/services/API/hooks/useAPIClient";

export default function useCreatePrebooking (languageId: number) {
    const client = useAPIClient();
    return useCallback(() => {
        return client.post<string>(`prebookings`, {
            'languageId': languageId
        });
    }, [client])
}
