import React from "react";
import PublicLayout from "modules/ui/components/PublicLayout";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import {useParams} from "react-router-dom";
import useBookingInfoGet from "../../modules/booking/hooks/useBookingInfoGet";
import BookingItem from "../../modules/booking/types/BookingItem";
import BookingItemCard from "./BookingItemCard";
import {Alert} from "../../modules/ui/shared/Alert/Alert";
import ButtonPrimary from "../../modules/ui/shared/Button/ButtonPrimary";
import BookingInfo from "../../modules/booking/types/BookingInfo";

function PageBookOk() {
    const { bookingInternalId } = useParams();
    const t = useTranslate();

    const bookingInfoQuery = useBookingInfoGet(bookingInternalId);
    const {data: bookingInfo} = bookingInfoQuery;

    const renderItem = (data:BookingItem) => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8 mb-10" key={data.item_id}>
                <BookingItemCard item={data} />
            </div>
        )
    };

    const showDirectPaymentMessage = (bookingInfo: BookingInfo): boolean => {
        const item = bookingInfo.items[0];

        return item.hotel_id !== 396; // Hotel National Clients
    };

    return (
        <PublicLayout>
            <div className={`nc-CheckOutPage`} data-nc-id="CheckOutPage">
                <main className="container mt-11 mb-12 lg:mb-18">
                    <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-2 px-0 sm:p-6 xl:p-8">
                        <h2 className="text-3xl lg:text-4xl font-semibold">
                            {t("bookOk.yourBooking")}
                        </h2>

                        {bookingInfo?.booking.confirmed ? (
                            <Alert type="success" closable={false} containerClassName="mb-5">
                                <div>
                                    <p className="mb-2">{t("bookOk.bookingConfirmed")}</p>
                                </div>
                            </Alert>
                        ) : null}

                        {bookingInfo?.booking.is_overbooking && (
                            <Alert type="warning" closable={false} containerClassName="mb-5">
                                <div>
                                    <p className="mb-2">{t("bookOk.roomsWithOverbookingDisclaimer")}</p>
                                </div>
                            </Alert>
                        )}

                        {bookingInfo?.booking.direct_payment && showDirectPaymentMessage(bookingInfo) ? (
                            <div>
                                <p>{t("bookOk.rememberPay")} {bookingInfo.booking.total_basket} <span dangerouslySetInnerHTML={{ __html:bookingInfo.booking.currency_symbol}} /></p>
                            </div>
                        ) : null}

                        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                        <div>
                            {bookingInfo && (
                            <div className="mt-6">
                                <div className="flex flex-col space-y-4 mb-10">
                                    <h3 className="text-2xl font-semibold mb-5">{t("booking.bookingDetails")}</h3>

                                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                        <span>{t("bookOk.confirmationNumber")}: </span>
                                        <span>#{bookingInfo.booking.id}</span>
                                    </div>

                                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                        <span>{t("bookOk.nameAndSurnames")}: </span>
                                        <span>{bookingInfo.booking.name} {bookingInfo.booking.surname}</span>
                                    </div>

                                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                        <span>{t("bookOk.email")}: </span>
                                        <span>{bookingInfo.booking.email}</span>
                                    </div>

                                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                        <span>{t("bookOk.telephone")}: </span>
                                        <span>+{bookingInfo.booking.dialCode} {bookingInfo.booking.telephone}</span>
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-4 mb-10">
                                    <h3 className="text-2xl font-semibold mb-5">{t("bookOk.hotelDetails")} </h3>

                                    <div className="flex flex-col space-y-4">
                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>{t("bookOk.event")}: </span>
                                            <span>{bookingInfo.event.name}</span>
                                        </div>
                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>{t("bookOk.city")}: </span>
                                            <span>{bookingInfo.booking.city_name}</span>
                                        </div>
                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>{t("bookOk.hotel")}: </span>
                                            <span>{bookingInfo.hotel.name}</span>
                                        </div>
                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>{t("bookOk.address")}: </span>
                                            <span>{bookingInfo.hotel.address}</span>
                                        </div>
                                        {bookingInfo.booking.direct_payment ? (
                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>{t("bookOk.payMethod")}: </span>
                                            <span>{t("bookOk.payOnHotel")}</span>
                                        </div>
                                        ) : null}
                                    </div>

                                </div>

                                <div className="flex flex-col space-y-4 mb-10">
                                    <h3 className="text-2xl font-semibold mb-5">{t("bookOk.roomDetails")} </h3>

                                    {bookingInfo.items ? bookingInfo.items.map((item:BookingItem) => renderItem(item))
                                    : null }

                                </div>

                                <div className="flex flex-col space-y-4 mb-10">
                                    <h3 className="text-2xl font-semibold mb-5">{t("bookOk.total")} </h3>
                                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

                                    <div className="flex flex-col space-y-4">
                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>{t("checkout.total")}</span>
                                            <div>{bookingInfo.booking.total_with_taxes} <span dangerouslySetInnerHTML={{ __html:bookingInfo.booking.currency_symbol}} /></div>
                                        </div>

                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>{t("checkout.total_without_taxes")}</span>
                                            <div>{bookingInfo.booking.total_without_taxes} <span dangerouslySetInnerHTML={{ __html:bookingInfo.booking.currency_symbol}} /></div>
                                        </div>
                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>{t("checkout.total_taxes")}</span>
                                            <div>{bookingInfo.booking.taxes} <span dangerouslySetInnerHTML={{ __html:bookingInfo.booking.currency_symbol}} /></div>
                                        </div>

                                        {bookingInfo.booking.discount && (
                                            <>
                                                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                                    <span className="text-red-500">
                                                        {t("checkout.discount")} {bookingInfo.booking.is_discount_percent && ('(' + bookingInfo.booking.discount + ' %)')}
                                                    </span>
                                                    <div className="text-red-500">
                                                        {'-'}{bookingInfo.booking.discount_amount}{' '}
                                                        <span dangerouslySetInnerHTML={{ __html:bookingInfo.booking.currency_symbol}} />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                                        <div className="flex justify-between font-semibold">
                                            <div className="flex flex-row">
                                                <span>{t("checkout.total_to_pay")}</span>
                                            </div>
                                            <div>{bookingInfo.booking.total_basket} <span dangerouslySetInnerHTML={{ __html:bookingInfo.booking.currency_symbol}} /></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            )}
                        </div>

                    </div>
                    <div className="text-center mt-10">
                        <ButtonPrimary className="uppercase" href="/">
                            {t("checkout.continueShopping")}
                        </ButtonPrimary>
                    </div>
                </main>
            </div>
        </PublicLayout>
    );
}

export default PageBookOk;
