import React, {useEffect, useState} from "react";
import Customer from "../types/Customer";
import useCustomerByDomainGet from "../hooks/useCustomerByDomainGet";
import useCustomerTranslationGet from "../hooks/useCustomerTranslationGet";
import CustomerTranslation from "../types/CustomerTranslation";
import {CUSTOMER_THEMES, LANG_ID_MAP} from "../constants";
import useChangeLanguage from "../../core/languages/hooks/useChangeLanguage";
import useCurrentLanguage from "../../core/languages/hooks/useCurrentLanguage";
import Loader from "../../ui/shared/Loader/Loader";

interface CustomerContextProps {
    languageId: number,
    setLanguageId: (languageId: number) => void,
    customer: Customer,
    customerTranslation: CustomerTranslation;
    theme: any;
}

export const CustomerContext = React.createContext<CustomerContextProps>({} as any);

const CustomerContextProvider = ({children}:{children:React.ReactNode}) => {
    const currentLang = useCurrentLanguage();
    const { data: customer } = useCustomerByDomainGet(window.location.hostname);
    const [languageId, setLanguageId] = useState(0);
    const { data: customerTranslation } = useCustomerTranslationGet(
        customer?.id || 0, languageId,
        { execute: !!customer?.id && !!languageId }
    );

    const changeLang = useChangeLanguage();

    useEffect(() => {
        if (!customer) return;

        if (!localStorage.getItem('customerWasLoaded')) {
            const newLangCode = customer.language[0].code;
            setLanguageId(LANG_ID_MAP[newLangCode]);
            changeLang(newLangCode);
            localStorage.setItem('customerWasLoaded', '1');
        }
    }, [customer, currentLang]);

    useEffect(() => {
        setLanguageId(LANG_ID_MAP[currentLang]);
    }, [currentLang]);

    const theme = customer ? CUSTOMER_THEMES[customer.type + ""] || null : null;

    return (
        customer && customerTranslation ?
            (
                <CustomerContext.Provider value={{
                    languageId,
                    setLanguageId,
                    customer,
                    customerTranslation,
                    theme
                }}>
                    {children}
                </CustomerContext.Provider>
            )
            : (
                <div className="flex items-center justify-center w-full h-screen">
                    <Loader />
                </div>
            )
    );
};

export default CustomerContextProvider;
