const translations = {
    mainNav: {
        language: "Idioma",
        shoppingBasket: "Carrito",
    },
    footer: {
        legalNotice: "Política legal",
        privacyPolicy: "Política de privacidad",
        cookiesPolicy: "Política de Cookies",
        complaintChannel: "Canal de Denuncia"
    },
    bookingForm: {
        event: "Evento",
        checkIn: "Desde",
        checkOut: "Hasta",
        guest: "Huésped",
        guests: "Huéspedes",
        addDate: "Agregar fecha",
        addGuest: "Agregar",
        numberOfRooms: "Habitaciones",
        adults: "Adultos",
        children: "Niños",
        roomNumber: "Habitación {{number}}"
    },
    cityEventMainCard: {
        hotels: "Hoteles",
        transferService: "Servicio de traslado",
        from: "Del",
        to: "hasta",
    },
    hotelMapCard: {
        minNightsRequired: "Este hotel requiere un mínimo de {{noches}} noches",
        discountCode: "{{code}}"
    },
    hotelDetail: {
        amenities: "Servicios",
        amenitiesDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        viewMoreAmenities: "ver más servicios",
        informationAndExtras: "Información y extras",
        informationAndExtrasDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        location: "Localización",
        cancellationPolicy: "Política de cancelación",
        modifyDates: "Modificar fechas",
        addRoom: "Añadir habitación",
        from: "Desde:",
        to: "Hasta:",
        capacity: "Capacidad:",
        total: "Total",
        roomAdded: "Habitación añadida",
        roomNotAvailable: "Habitación no disponible",
        roomNotAvailableAt: "No hay disponibilidad para estas noches: {{dates}}",
        roomAvailableWithOverbooking: "Habitación con dias pendientes de confirmación",
        roomAvailableWithOverbookingAt: `Al realizar la reserva el personal del hotel confirmará si hay disponibilidad para estas noches: {{dates}}`,
        roomAvailableWithOverbookingConfirm: `Si estás de acuerdo pulsa confirmar para reservar`,
        hotelNotAvailable: "No puede agregar habitaciones de hoteles diferentes",
        addRoomErrorTitle: "Ocurrió un error",
        addRoomError: "Ocurrió un error y no se pudo añadir la habitación al carrito",
        goToCheckout: "Ir a la cesta",
        details: "Detalles",
    },
    common: {
        actions: {
            add: "Añadir",
            accept: "Aceptar",
            cancel: "Cancelar",
            edit: "Editar",
            delete: "Eliminar",
            submit: "Enviar",
            save: "Guardar",
            upload_file: "Subir archivo",
            search: "Buscar",
            show_more_filters: "Mostrar más filtros",
            hide_more_filters: "Ocultar más filtros",
            clear: "Limpiar",
            back: "Volver",
            goHome: "Ir a inicio",
            copy: "Copiar",
            confirm: "Confirmar",
        },
        labels: {
            required_field: "Campo obligatorio",
            filters: "Filtros",
            none: "Ninguno",
            all: "Todos",
            creation_date: "Fecha de creación",
            error: "Error",
            administration: "Administración",
            actions: "Acciones",
            selected_items: "{{count}} elemento/s seleccionado/s",
            per_page: "Por página",
            sort_by: "Ordenar por",
            total: "Total",
            language: "Idioma",
        },
        feedback: {
            saved_data: "Datos guardados con éxito",
            no_data: "No hay datos",
            form_not_valid: "Hay errores en el formulario",
            field_required: "El campo es obligatorio",
            field_min: "El valor del campo no puede ser inferior a {{min}}",
            field_max: "El valor del campo no puede ser superior a {{max}}",
            field_min_length:
                "La longitud del campo no puede ser menor a {{length}}",
            field_max_length:
                "La longitud del campo no puede ser mayor a  {{length}}",
            field_valid_email: "{{value}} no es un correo electrónico válido",
            field_valid_iban: "{{value}} no es un IBAN correcto",
            unexpected_error: "Ha ocurrido un error inesperado",
        },
    },

    auth: {
        actions: {
            sign_in: "Iniciar sesión",
            logout: "Cerrar sesión",
            reset_password: "Restablecer contraseña",
        },
        fields: {
            email: "Correo electrónico",
            password: "Contraseña",
            new_password: "Nueva contraseña",
            confirm_new_password: "Confirmar nueva contraseña",
        },
        labels: {
            forgot_password: "¿Has olvidado la contraseña?",
            forgot_password_subheading:
                "Introduce tu correo electrónico para restablecer tu contraseña",
        },
        feedback: {
            unauthorized: "La sesión no es válida",
            passwords_does_not_match: "Las contraseñas no coinciden",
        },
    },

    error404: {
        labels: {
            heading:
                "Lo sentimos, parece que no podemos encontrar la página que estás buscando.",
            subheading:
                "Es posible que haya un error ortográfico en la URL introducida o que la página que estás buscando ya no exista.",
        },
    },

    home: {
        title: "Inicio",
    },
    cart: {
        title: "Carrito",
    },
    checkout: {
        title: "Detalles de reserva",
        continueShopping: "Continuar comprando",
        noItemsInTheCart: "No hay nada en el carrito",
        total: "Total",
        total_without_taxes: "Total sin IVA",
        total_taxes: "IVA",
        discount: "Descuento",
        total_to_pay: "Total a pagar",
        confirm_booking: "Confirmar reserva",
        clear_basket: "Vaciar carrito",
        total_nights: "Noches",
        nights: "noches",
        night_of: "Noche del",
        from: "Desde",
        to: "Hasta",
        delete: "Eliminar",
        book_price_detail: "Detalle de precios"
    },
    booking: {
        title: "Confirmar reserva",
        personalInformation: "Información personal",
        name: "Nombre",
        surname: "Apellidos",
        email: "Correo electrónico",
        telephone: "Teléfono",
        invalidTelephone: "El teléfono no es válido",
        roomsWithOverbookingDisclaimer: "Al finalizar la reserva nos pondremos en contacto contigo lo antes posible ya que esta reserva quedará pendiente de confirmación para los siguiente días",
        roomsToBePaidAtTheHotelDisclaimer: "Esta reserva contiene habitaciones para pagar en el hotel, los detalles de la tarjeta de crédito son necesarios para garantizar la habitación con el hotel.",
        paymentRequiredDisclaimer: "Alguien del equipo de Love Events se pondrá en contacto contigo en breve para realizar el pago.",
        creditCardInformation: "Información de la tarjeta de crédito",
        creditCardHolder: "Titular de la tarjeta de crédito",
        creditCardNumber: "Número de la tarjeta de crédito",
        invalidCreditCardNumber: "Por favor introduce un número de tarjeta de crédito válido",
        expiration: "Caducidad",
        invalidCreditCardExpiration: "La fecha de caducidad de la tarjeta de crédito debe ser posterior a la de la reserva",
        roomDetails: "Detalles de las habitaciones",
        room: "Habitación",
        guests: "Huéspedes",
        guestN: "Huésped {{number}}",
        child: "Niño",
        checkInTime: "Hora de entrada",
        iDontKnow: "No lo sé",
        comments: "Comentarios",
        acceptTerms: "He leído y acepto los ",
        termsAndConditions: "Términos y Condiciones",
        submit: "Finalizar la reserva",
        formContainsErrors: "Hay campos inválidos en el formulario. Por favor revisa el formulario y vuelve a pulsar \"Finalizar reserva\".",
        errorWhenMakingTheBookingHeading: "Error al hacer la reserva",
        errorWhenMakingTheBookingBody: "Ha ocurrido un error al hacer la reserva. Por favor vuelve a intentarlo. Si el problema persiste contacta con nosotros.",
        prebookingHasExpiredError: "El carrito ha caducado tras 15 minutos de iniciar el proceso sin finalizarlo. Por favor, vuelve a empezar el proceso de reserva",
        bookingDetails: "Detalles de la reserva"
    },
    bookOk: {
        yourBooking: "Su reserva",
        bookingConfirmed: "Su reserva ha sido confirmada",
        roomsWithOverbookingDisclaimer: "Su reserva está pendiente de ser confirmada. Nos pondremos en contacto con usted lo antes posible",
        rememberPay: "Recuerda que necesitarás pagar el precio de ",
        confirmationNumber: "Número de confirmación",
        nameAndSurnames: "Nombre y apellidos",
        email: "Email de contacto",
        telephone: "Teléfono de contacto",
        hotelDetails: "Detalles del hotel",
        event: "Evento",
        city: "Ciudad",
        hotel: "Hotel",
        address: "Dirección",
        payMethod: "Método de pago",
        payOnHotel: "Pago directo en el hotel",
        roomDetails: "Detalle de habitaciones",
        roomType: "Tipo de habitación",
        occupancy: "Nº de personas",
        arrivalHour: "Hora de llegada",
        guest: "Huésped",
        total: "Total"
    }
};

export default translations;
