import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  isInvalid?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-2xl",
      children,
      type = "text",
      isInvalid = false,
      ...args
    },
    ref
  ) => {
    const borderClass = isInvalid ?
        "border-red-400 focus:border-red-500 dark:border-red-800"
        : "border-neutral-200 focus:border-primary-300 dark:border-neutral-700";

    return (
      <input
        ref={ref}
        type={type}
        className={`block w-full focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${borderClass} ${className}`}
        {...args}
      />
    );
  }
);

export default Input;
