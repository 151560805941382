import React, {FC} from "react";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import NcImage from "../../modules/ui/shared/NcImage/NcImage";
import {StarIcon} from "@heroicons/react/24/solid";
import BookingItem from "../../modules/booking/types/BookingItem";
import BookingItemGuest from "../../modules/booking/types/BookingItemGuest";
import moment from "moment";

export interface BookingItemCardProps {
    item: BookingItem;
}

const BookingItemCard: FC<BookingItemCardProps> = ({
    item,
}) => {
    const t = useTranslate();

    console.log({item})

    return (
        <>
            <div className="flex flex-col sm:flex-row sm:items-center">
                <div className="flex-shrink-0 w-full sm:w-40">
                    <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                        <NcImage src={(process.env.REACT_APP_API_HOTEL_STORAGE as string) + item.hotel_id + "/" + item.hotel_image} />
                    </div>
                </div>
                <div className="py-5 sm:px-5 space-y-3 max-w-[33%]">
                    <div>
                        <span className="text-base font-medium mt-1 block">
                            <div className="flex items-end">
                                {[...Array(item.hotel_stars)].map((value, index) => <StarIcon className="w-[18px] h-[18px] text-orange-500" key={index} />)}
                            </div>
                            {item.hotel_name}
                        </span>
                    </div>
                    <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                            {t("checkout.from")}: {moment(item.from).format('DD/MM/YYYY')}
                    </span>
                    <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                            {t("checkout.to")}: {moment(item.to).format('DD/MM/YYYY')}
                    </span>
                    <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                </div>
                <div className="py-5 sm:px-5 space-y-3 w-4/6">
                    <div className="flex flex-col space-y-4">
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            <span>{t("bookOk.roomType")}:</span>
                            <span>{item.room_name}</span>
                        </div>
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            <span>{t("bookOk.occupancy")}</span>
                            <span className="ml-2">
                            {[...Array(item.occupancy_adults)].map((value, index) =>
                                <i className="las la-user la-2x" key={index + item.item_id}></i>
                            )}
                                {[...Array(item.occupancy_children)].map((value, index) =>
                                    <i className="text-2xl las la-user" key={index + item.item_id}></i>
                                )}
                        </span>
                        </div>
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            <span>{t("bookOk.arrivalHour")}</span>
                            <span>{item.checkin ? item.checkin : 'n/a'}</span>
                        </div>
                        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                            {item.guests && item.guests.map((guest:BookingItemGuest, index) => (
                                <div className="flex justify-between font-semibold" key={index}>
                                    <div className="flex flex-row">
                                        <span>{t("bookOk.guest")} {guest.number} {index >= item.occupancy - item.occupancy_children ? ` (${t("booking.child")})` : null}</span>
                                    </div>
                                    <div>{guest.name} {guest.surnames}</div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingItemCard;
