import Customer from "../types/Customer";
import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";

export default function useCustomerByDomainGet (
    domain: string,
    options?: CacheQueryOpts
): CacheQueryOutput<Customer> {
    const client = useAPIClient();
    return useCacheQuery<Customer>(
        ["customer", domain],
        () => {
            return client.get<any>(`customer/by-domain`, {
                "domain": domain
            });
        },
        options
    );
}