import React, {FC} from "react";
import BookingDates from "../../modules/booking/types/BookingDates";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import NcModal from "../../modules/ui/shared/NcModal/NcModal";
import Badge from "../../modules/ui/shared/Badge/Badge";
import ButtonSecondary from "../../modules/ui/shared/Button/ButtonSecondary";
import ButtonPrimary from "../../modules/ui/shared/Button/ButtonPrimary";

interface HotelRoomFeedbackModalProps {
    kind: "roomAdded" | "roomAvailableWithOverbooking" | "roomNotAvailable" | "hotelNotAvailable" | "error"
    unavailableDates?: BookingDates[]
    overbookingDates?: BookingDates[],
    isOpen: boolean,
    onClose: () => void;
    onConfirmAddRoom: () => void;
}

const HotelRoomFeedbackModal: FC<HotelRoomFeedbackModalProps> = ({
    kind,
    unavailableDates,
    overbookingDates,
    isOpen,
    onClose,
    onConfirmAddRoom,
}) => {
    const t = useTranslate();

    let modalTitle;

    switch (kind) {
        case "roomAdded":
            modalTitle = t("hotelDetail.roomAdded");
            break;
        case "roomNotAvailable":
            modalTitle = t("hotelDetail.roomNotAvailable");
            break;
        case "roomAvailableWithOverbooking":
            modalTitle = t("hotelDetail.roomAvailableWithOverbooking");
            break;
        case "hotelNotAvailable":
            modalTitle = t("hotelDetail.hotelNotAvailable");
            break;
        case "error":
            modalTitle = t("hotelDetail.addRoomErrorTitle");
            break;
    }

    const modalContent = (
        <div className="text-center">
            {kind === 'error' && (
                <>
                    <i className="text-9xl las la-times-circle la-fw text-red-500 mb-1"/>
                    <p className="text-1xl">{t("hotelDetail.addRoomError")}</p>
                </>
            )}
            {kind === 'roomAdded' && (
                <>
                    <i className="text-9xl las la-check-circle la-fw text-green-600 mb-1"/>
                    <p className="text-1xl">{t("hotelDetail.roomAdded")}</p>
                </>
            )}

            {kind === 'roomNotAvailable' && (
                <>
                    <i className="text-9xl las la-exclamation-circle la-fw text-orange-500 mb-1"/>
                    <div className="text-1xl">
                        <p>{t("hotelDetail.roomNotAvailableAt")}</p>

                        <div className="space-x-1">
                            {unavailableDates?.map((item) => (
                                <Badge key={item.fecha} name={item.fecha} color="red"/>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {kind === 'hotelNotAvailable' && (
                <>
                    <i className="text-9xl las la-exclamation-circle la-fw text-orange-500 mb-1"/>
                    <p className="mb-4">
                        {t("hotelDetail.hotelNotAvailable")}
                    </p>
                </>
            )}


            {kind === 'roomAvailableWithOverbooking' && (
                <>
                    <i className="text-9xl las la-exclamation-circle la-fw text-orange-500 mb-1"/>
                    <div className="mb-4">
                        <p>{t("hotelDetail.roomAvailableWithOverbookingAt")}</p>

                        <div className="space-x-1">
                            {overbookingDates?.map((item) => (
                                <Badge key={item.fecha} name={item.fecha} color="yellow"/>
                            ))}
                        </div>
                    </div>
                    <p>{t("hotelDetail.roomAvailableWithOverbookingConfirm")}</p>
                </>
            )}

            <div className="flex justify-between pt-8">
                <ButtonSecondary onClick={onClose}>
                    {t(kind === 'roomAvailableWithOverbooking' ? "common.actions.cancel" : "common.actions.back")}
                </ButtonSecondary>
                {kind === 'roomAvailableWithOverbooking' && (
                    <ButtonPrimary onClick={onConfirmAddRoom}>{t("common.actions.confirm")}</ButtonPrimary>)}
                {kind === 'roomAdded' && (
                    <ButtonPrimary href="/checkout">{t("hotelDetail.goToCheckout")}</ButtonPrimary>)}
            </div>
        </div>
    );

    return (
        <NcModal
            contentExtraClass="max-w-screen-sm"
            modalTitle={modalTitle}
            renderContent={() => modalContent}
            renderTrigger={() => null}
            isOpenProp={isOpen}
            onCloseModal={onClose}
        />
    )
}

export default HotelRoomFeedbackModal;
