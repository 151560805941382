import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import EventCityTranslation from "../types/EventCityTranslation";

export default function useEventCityByEventAndLanguageGet (
    eventCityId: any,
    languageId: number,
    options?: CacheQueryOpts
): CacheQueryOutput<EventCityTranslation> {
    const client = useAPIClient();
    return useCacheQuery<EventCityTranslation>(
        ["event-city-translation", eventCityId, languageId],
        () => {
            return client.get<any>(`event-city-translation`, {
                "eventCityId": eventCityId,
                "languageId": languageId
            });
        },
        options
    );
}