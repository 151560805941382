import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import HotelRoom from "../types/HotelRoom";

type Output = HotelRoom[];

export default function useHotelGetRoomsInfo (
    hotelId: any,
    languageId: number,
    eventCityId: any,
    startOn: any,
    endOn: any,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["hotel-room-by-hotel", hotelId, languageId, eventCityId],
        () => {
            return client.get<any>(`hotel-rooms/hotel/`+hotelId, {
                "languageId": languageId,
                "eventCityId": eventCityId,
                "startOn": startOn,
                "endOn": endOn
            });
        },
        options
    );
}