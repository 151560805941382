import React, {FC} from "react";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import BasketItem from "../../modules/booking/types/BasketItem";
import CheckoutItem from "./CheckoutItem";
import ButtonPrimary from "../../modules/ui/shared/Button/ButtonPrimary";
import ButtonSecondary from "../../modules/ui/shared/Button/ButtonSecondary";
import useDeleteAllBasketItems from "../../modules/booking/hooks/useDeleteAllBasketItems";
import useGetPrebooking from "../../modules/booking/hooks/useGetPrebooking";

export interface CheckoutItemListProps {
    prebookingId: string;
    basketItems: BasketItem[];
}

const CheckoutItemList: FC<CheckoutItemListProps> = ({
    prebookingId,
    basketItems,
}) => {
    const t = useTranslate();

    const {save: deleteBasketItems } = useDeleteAllBasketItems();

    const prebookingQuery = useGetPrebooking(prebookingId);
    const {data: prebooking} = prebookingQuery;

    const handleClearBasket = () => {
        deleteBasketItems({
            prebookingId: prebookingId
        });
    }

    const renderItem = (data: BasketItem) => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8 mb-10" key={data.item_id}>
                <CheckoutItem item={data} />
            </div>
        )
    };

    return (
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
            <h2 className="text-3xl lg:text-4xl font-semibold">
                {t("checkout.title")}
            </h2>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div>
                {basketItems.map((item) => renderItem(item))}
            </div>

            {prebooking && (
            <div className="py-5 sm:px-5 space-y-3 w-full">
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>{t("checkout.total")}</span>
                        <div>{prebooking.total_with_taxes} <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} /></div>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>{t("checkout.total_without_taxes")}</span>
                        <div>{prebooking.total_without_taxes} <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} /></div>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>{t("checkout.total_taxes")}</span>
                        <div>{prebooking.taxes} <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} /></div>
                    </div>

                    {prebooking.discount && (
                    <>
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            <span className="text-red-500">
                                {t("checkout.discount")} {prebooking.is_discount_percent && ('(' + prebooking.discount + ' %)')}
                            </span>
                            <div className="text-red-500">
                                {'-'}{prebooking.discount_amount}{' '}
                                <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} />
                            </div>
                        </div>
                    </>
                    )}

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <div className="flex flex-row">
                            <span>{t("checkout.total")}</span>
                        </div>
                        <div>{prebooking.total_basket} <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} /></div>
                    </div>
                </div>
            </div>
            )}

            <div>
                <ButtonPrimary href={"/book"}>
                    <i className={"text-3xl las la-credit-card"}></i>
                    <span className="pl-1">{t("checkout.confirm_booking")}</span>
                </ButtonPrimary>
                <ButtonSecondary onClick={handleClearBasket} className="float-right">{t("checkout.clear_basket")}</ButtonSecondary>
            </div>
        </div>
    );
}

export default CheckoutItemList;
