import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "modules/ui/components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSearch from "./ButtonSearch";
import useTranslate from "../../../core/languages/hooks/useTranslate";
import { GuestsObject } from "../../types";
import { MAX_ADULT_GUESTS, MAX_CHILDREN_GUESTS } from "../../constants";

export interface GuestsInputProps {
  defaultValue: GuestsObject[];
  onChange?: (data: GuestsObject[]) => void;
  fieldClassName?: string;
  className?: string;
  hasButtonSubmit?: boolean;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  hasButtonSubmit = true,
}) => {
  const t = useTranslate();

  const [guestValues, setGuestValues] = useState(defaultValue);

  useEffect(() => {
    setGuestValues(defaultValue);
  }, [defaultValue]);

  const handleChangeData = useCallback((value: number, type: keyof GuestsObject, roomIndex: number) => {
    const newValue = [...guestValues];
    newValue[roomIndex][type] = value;
    setGuestValues(newValue);
    onChange && onChange(newValue);
  }, [guestValues, onChange]);

  const handleChangeNOfRooms = useCallback((nOfRooms: number) => {
    if (nOfRooms < guestValues.length) {
      const newValue = [...guestValues.slice(0, nOfRooms)];
      setGuestValues(newValue);
      onChange && onChange(newValue);
    } else if(nOfRooms > guestValues.length) {
      const nOfRoomToAdd = nOfRooms - guestValues.length;
      const guestsToAdd = { guestAdults: 1, guestChildren: 0 };
      const newValue = [...guestValues, ...Array(nOfRoomToAdd).fill(guestsToAdd)];
      setGuestValues(newValue);
      onChange && onChange(newValue);
    }
  }, [guestValues, onChange]);

  const handleClear = useCallback(() => {
    const newValue = [{ guestAdults: 1, guestChildren: 0 }];
    setGuestValues(newValue);
    onChange && onChange(newValue);
  }, [onChange]);

  const totalGuests = useMemo(() => {
    let total = 0;

    for (const roomGuests of guestValues) {
      total += (roomGuests.guestAdults || 0) + (roomGuests.guestChildren || 0);
    }

    return total;
  }, [guestValues]);

  const nOfRooms = guestValues.length;

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex flex-col lg:flex-row items-start lg:items-center focus:outline-none cursor-pointer ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center ${fieldClassName} space-x-3 `}
              onClick={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold whitespace-nowrap">
                  {totalGuests || ""} {totalGuests > 1 ? t("bookingForm.guests") : t("bookingForm.guest")}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light whitespace-nowrap">
                  {totalGuests ? t("bookingForm.guests") : t("bookingForm.addGuest")}
                </span>
              </div>
            </Popover.Button>

            <div className="relative">
              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={handleClear}
                />
              )}
            </div>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pl-2 lg:pl-0 pr-2 pb-4 lg:pb-0 xl:pr-4 w-full lg:w-auto">
                <ButtonSearch type="submit" />
              </div>
            )}
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] md:min-w-[600px] bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                  className="mb-6 w-full md:w-1/2"
                  defaultValue={nOfRooms}
                  onChange={handleChangeNOfRooms}
                  min={1}
                  label={t("bookingForm.numberOfRooms")}
              />

              <div className="w-full flex justify-end mb-3">
                <div className="w-1/3 font-medium text-neutral-800 dark:text-neutral-200">{t("bookingForm.adults")}</div>
                <div className="w-1/3 font-medium text-neutral-800 dark:text-neutral-200">{t("bookingForm.children")}</div>
              </div>

              {guestValues.map(({ guestAdults, guestChildren }, roomIndex) => (
                  <div key={roomIndex} className="w-full flex items-center py-3 border-t">
                    <div className="w-1/3 font-medium text-neutral-500 dark:text-neutral-200 pr-1 overflow-hidden">
                      {t("bookingForm.roomNumber", { number: roomIndex + 1 })}
                    </div>
                    <NcInputNumber
                        className="w-1/3 pr-1"
                        defaultValue={guestAdults}
                        onChange={(value) => handleChangeData(value, "guestAdults", roomIndex)}
                        max={MAX_ADULT_GUESTS}
                        min={1}
                    />
                    <NcInputNumber
                        className="w-1/3"
                        defaultValue={guestChildren}
                        onChange={(value) => handleChangeData(value, "guestChildren", roomIndex)}
                        max={MAX_CHILDREN_GUESTS}
                        min={0}
                    />
                  </div>
              ))}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
