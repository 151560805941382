import React from "react";
import BgGlassmorphism from "modules/ui/components/BgGlassmorphism/BgGlassmorphism";
import PublicLayout from "modules/ui/components/PublicLayout";
import useBookingSearchContext from "../../modules/booking/hooks/useBookingSearchContext";
import CheckoutItemList from "./CheckoutItemList";
import ButtonPrimary from "../../modules/ui/shared/Button/ButtonPrimary";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import useGetBasketItems from "../../modules/booking/hooks/useGetBasketItems";
import useCustomerContext from "../../modules/customers/hooks/useCustomerContext";
import {Alert} from "../../modules/ui/shared/Alert/Alert";
import Loader from "../../modules/ui/shared/Loader/Loader";

function PageCheckout() {
    const { prebookingId } = useBookingSearchContext();
    const t = useTranslate();
    const navigate = useNavigate();
    const { city } = useBookingSearchContext();
    const { languageId } = useCustomerContext();

    const basketItemsQuery = useGetBasketItems(prebookingId, languageId, { execute: !!prebookingId });
    const {data: basketItems, isLoading: isLoadingBasketItems} = basketItemsQuery;

    const handleContinueShoppingButton = () => {
        if (city) {
            navigate("/event-city/" + city?.id);
        } else {
            navigate("/");
        }
    }

    return (
        <PublicLayout>
            <div className="nc-PageHome relative overflow-hidden mt-4">

                <BgGlassmorphism />

                <div className="container relative space-y-5 mb-24 lg:space-y-5">
                    { prebookingId && basketItems?.length ?
                        <CheckoutItemList prebookingId={prebookingId} basketItems={basketItems} />
                        :
                        isLoadingBasketItems
                            ? <Loader centerHorizontally />
                            : <Alert type="info" closable={false}>{t("checkout.noItemsInTheCart")}</Alert>
                    }
                    <p className="text-center">
                        <ButtonPrimary className="uppercase" onClick={handleContinueShoppingButton}>
                            {t("checkout.continueShopping")}
                        </ButtonPrimary>
                    </p>
                </div>
            </div>
        </PublicLayout>
    );
}

export default PageCheckout;
