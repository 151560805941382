import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import EventCity from "../types/EventCity";

type Output = EventCity;

export default function useEventCityGet (
    eventCityId: any,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["event-city", eventCityId],
        () => {
            return client.get<any>(`event-city/` + eventCityId, {

            });
        },
        options
    );
}