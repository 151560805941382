import { useQuery } from "react-query";
import OperationStatus from "../types/OperationStatus";

export interface CacheQueryOpts<T = any> {
    cacheTimeSeconds?: number;
    execute?: boolean;
    onSuccess?: (data: T) => void;
    refetchOnMount?: boolean | "always";
}

export interface CacheQueryOutput<T = any> {
    status: OperationStatus;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    isIdle: boolean;
    data?: T;
    error: Error | null;
}

export default function useCacheQuery<T = any>(
    key: string | unknown[],
    callback: () => Promise<T>,
    options: CacheQueryOpts<T> = {}
): CacheQueryOutput<T> {
    const {
        status,
        isLoading: useQueryIsLoading,
        isError,
        isSuccess,
        isIdle,
        data,
        error,
        isFetching,
    } = useQuery<T, Error>(key, callback, {
        cacheTime: options.cacheTimeSeconds
            ? options.cacheTimeSeconds * 1000
            : 300000, //300000ms = 5min,
        enabled: options.execute ?? undefined,
        refetchOnWindowFocus: false,
        onSuccess: options.onSuccess,
        refetchOnMount: options.refetchOnMount ?? true,
        retry: false,
    });

    const isLoading = useQueryIsLoading || isFetching;

    return {
        data: isLoading ? undefined : data,
        error,
        isLoading,
        isError: isLoading ? false : isError,
        isSuccess: isLoading ? false : isSuccess,
        isIdle: isLoading ? false : isIdle,
        status: getStatus(isLoading ? "loading" : status),
    };
}

const getStatus = (reactQueryStatus: string): OperationStatus => {
    switch (reactQueryStatus) {
        case "loading":
            return OperationStatus.Loading;
        case "error":
            return OperationStatus.Error;
        case "success":
            return OperationStatus.Success;
        case "idle":
        default:
            return OperationStatus.Idle;
    }
};
