import React, {FC} from "react";
import BasketItem from "modules/booking/types/BasketItem";
import NcImage from "modules/ui/shared/NcImage/NcImage";
import {StarIcon} from "@heroicons/react/24/solid";
import Prebooking from "../../modules/booking/types/Prebooking";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";

interface SidebarProps {
    basketItems: BasketItem[] | undefined;
    prebooking: Prebooking | undefined;
}

const Sidebar: FC<SidebarProps> = ({ basketItems = [], prebooking }) => {
    const t = useTranslate();
    const renderItem = (item: BasketItem) => {
        return (
            <div key={item.item_id}>
                <div className="flex flex-col sm:flex-row sm:items-center">
                    <div className="flex-shrink-0 w-full sm:w-40">
                        <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                            <NcImage src={(process.env.REACT_APP_API_HOTEL_STORAGE as string) + item.hotel_id + "/" + item.hotel_image} />
                        </div>
                    </div>
                    <div className="py-5 sm:px-5 space-y-3">
                        <div>
                            <span className="text-base font-medium mt-1 block">
                                <div className="flex items-end">
                                    {[...Array(item.hotel_stars)].map((value, index) => <StarIcon className="w-[18px] h-[18px] text-orange-500" key={index} />)}
                                </div>
                                {item.hotel_name}
                            </span>
                        </div>
                        <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                            {item.room_name}
                        </span>
                        <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                            {t("checkout.from")}: {item.book_from_to_front}
                        </span>
                        <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                            {t("checkout.to")}: {item.book_to_to_front}
                        </span>
                        <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                    </div>
                </div>

                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>{t("checkout.total_nights")}</span>
                        <span>{item.total_nights} {t("checkout.nights")}</span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                    <div className="flex justify-between">
                        <div className="flex flex-row">
                            <span>{t("checkout.total")}</span>
                        </div>
                        <div>{item.price_with_taxes} <span dangerouslySetInnerHTML={{ __html:item.currency_symbol}} /></div>
                    </div>
                </div>
            </div>
        )
    };

    return (
            <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">

            {basketItems ? basketItems.map((item) => renderItem(item))
                : null }

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

            {prebooking && (
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>{t("checkout.total")}</span>
                        <div>{prebooking.total_with_taxes} <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} /></div>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>{t("checkout.total_without_taxes")}</span>
                        <div>{prebooking.total_without_taxes} <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} /></div>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>{t("checkout.total_taxes")}</span>
                        <div>{prebooking.taxes} <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} /></div>
                    </div>

                    {prebooking.discount && (
                        <>
                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span className="text-red-500">
                        {t("checkout.discount")} {prebooking.is_discount_percent && ('(' + prebooking.discount + ' %)')}
                    </span>
                                <div className="text-red-500">
                                    {'-'}{prebooking.discount_amount}{' '}
                                    <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} />
                                </div>
                            </div>
                        </>
                    )}

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <div className="flex flex-row">
                            <span>{t("checkout.total_to_pay")}</span>
                        </div>
                        <div>{prebooking.total_basket} <span dangerouslySetInnerHTML={{ __html:basketItems[0].currency_symbol}} /></div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Sidebar;
