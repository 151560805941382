import React, { FC } from "react";

export interface LabelProps {
  className?: string;
  isInvalid?: boolean;
  children?: React.ReactNode;
}

const Label: FC<LabelProps> = ({ className = "", isInvalid = false, children }) => {
  const colorClass = isInvalid ? "text-red-600 dark:text-red-400" : "text-neutral-700 dark:text-neutral-300";

  return (
    <label
      className={`nc-Label text-sm font-medium whitespace-nowrap overflow-hidden overflow-ellipsis ${colorClass} ${className}`}
      data-nc-id="Label"
      title={typeof children === 'string' ? children : undefined}
    >
      {children}
    </label>
  );
};

export default Label;
