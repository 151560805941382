import React, { FC } from "react";
import useCountBasketItems from "../../../booking/hooks/useCountBasketItems";
import Badge from "../../shared/Badge/Badge";

interface BasketBadgeProps {
    basketId: string;
}

const BasketBadge: FC<BasketBadgeProps> = (
    basketId
) => {
    const basketItemsQuery = useCountBasketItems(basketId.basketId);
    const {data: basketItems} = basketItemsQuery;

    return (
        <>
        {basketItems && (
            <Badge name={basketItems?.total} color="green" className="ml-2"/>
        )}
        </>
    );
};

export default BasketBadge;
