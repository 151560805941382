export const formatNumber = (n: number): string =>
    new Intl.NumberFormat("de-DE",  { minimumFractionDigits: 2 }).format(n);

export const randomInt = (min = 1, max = 99999): number =>
    Math.floor(Math.random() * (max - min + 1) + min);

export const formatMoney = (amount: number): string => {
    const formatter = Intl.NumberFormat("es-ES", {
        style: "currency"
    });

    return formatter.format(amount);
};
