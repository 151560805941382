import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import CityTranslation from "../types/CityTranslation";

export default function useCityTranslationByCityAndLanguageGet (
    cityId: number,
    languageId: number,
    options?: CacheQueryOpts
): CacheQueryOutput<CityTranslation> {
    const client = useAPIClient();
    return useCacheQuery<CityTranslation>(
        ["city-translation", cityId, languageId],
        () => {
            return client.get<any>(`city-translation-by-city-and-language`, {
                "cityId": cityId,
                "languageId": languageId
            });
        },
        options
    );
}