import React, { FC } from "react";
import HeroSearchForm from "modules/booking/components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  mainImage: string;
  className?: string;
  showBookForm?: boolean;
  imageContainerClassName?: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  mainImage,
  className = "",
  imageContainerClassName = "",
  showBookForm = true,
}) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className={`flex flex-col lg:flex-row lg:items-center ${imageContainerClassName}`}>
        <div className="flex-grow">
          <img className="w-full" src={mainImage} alt="hero" />
        </div>
      </div>

      {showBookForm &&
        <div className="flex justify-center mb-12 mb-0 -mt-10 lg:-mt-40 w-full">
          <HeroSearchForm />
        </div>
      }
    </div>
  );
};

export default SectionHero;
