const translations = {
    mainNav: {
        language: "Language",
        shoppingBasket: "Panier",
    },
    footer: {
        legalNotice: "Avis juridique",
        privacyPolicy: "Politique de confidentialité",
        cookiesPolicy: "Politique de cookies",
        complaintChannel: "Canal de réclamation"
    },
    bookingForm: {
        event: "Événement",
        checkIn: "Arrivée",
        checkOut: "Départ",
        guest: "Personne",
        guests: "Personnes",
        addDate: "Add date",
        addGuest: "Add",
        numberOfRooms: "Chambres",
        adults: "Adultes",
        children: "Enfants",
        roomNumber: "Chambre {{number}}"
    },
    cityEventMainCard: {
        hotels: "Hôtels",
        transferService: "Service de transferts",
        from: "Du",
        to: "Février",
    },
    hotelMapCard: {
        minNightsRequired: "Cet hôtel nécessite un minimum de {{nights}} nuits",
    },
    hotelDetail: {
        amenities: "Points forts de la propriété",
        viewMoreAmenities: "view more amenities",
        informationAndExtras: "Informations et extras",
        location: "Location",
        cancellationPolicy: "Politique d'annulation",
        modifyDates: "Modifier les dates",
        addRoom: "Ajouter chambre",
        from: "Du:",
        to: "À:",
        capacity: "Capacité:",
        total: "Prix",
        roomAdded: "Pièce ajoutée",
        roomNotAvailable: "Chambre non disponible",
        roomNotAvailableAt: "Il n'y a pas de disponibilité pour ces nuits: {{dates}}",
        roomAvailableWithOverbooking: "Chambre avec jours en attente de confirmation",
        roomAvailableWithOverbookingAt: "Lors de la réservation, le personnel de l'hôtel confirmera s'il y a de la disponibilité pour ces nuits: {{dates}}",
        roomAvailableWithOverbookingConfirm: "Si vous êtes d'accord, cliquez sur confirmer pour réserver.",
        hotelNotAvailable: "Vous ne pouvez pas ajouter de chambres de différents hôtels",
        addRoomErrorTitle: "An error ocurred.",
        addRoomError: "An error occurred and the room could not be added to the basket",
        goToCheckout: "Aller à la caisse",
        details: "Details",
    },
    common: {
        actions: {
            add: "Ajouter",
            accept: "Accepter",
            cancel: "Annuler",
            edit: "Modifier",
            delete: "Supprimer",
            submit: "Soumettre",
            save: "Enregistrer",
            upload_file: "Télécharger le fichier",
            search: "Chercher",
            show_more_filters: "Afficher plus de filtres",
            hide_more_filters: "Masquer plus de filtres",
            clear: "Effacer",
            back: "retour",
            goHome: "Aller à la maison",
            copy: "Copier",
            confirm: "Confirmer",
        },
        labels: {
            required_field: "Champ obligatoire",
            filters: "Filtres",
            none: "Aucun",
            all: "Tout le monde",
            creation_date: "Date de création",
            error: "Erreur",
            administration: "Administration",
            actions: "Actions",
            selected_items: "{{count}} élément(s) sélectionné(s)",
            per_page: "Par page",
            sort_by: "Tier par",
            total: "Totale",
            language: "Langue",
        },
        feedback: {
            saved_data: "Données enregistrées avec succès",
            no_data: "Aucune donnée",
            form_not_valid: "Il y a des erreurs dans le formulaire",
            field_required: "Le champ est obligatoire",
            field_min: "La valeur du champ ne peut pas être inférieure à {{min}}",
            field_max: "La valeur du champ ne peut pas être supérieure à {{max}}",
            field_min_length:
                "La longueur du champ ne peut pas être inférieure à {{length}}",
            field_max_length:
                "La longueur du champ ne peut pas être supérieure à {{length}}",
            field_valid_email: "{{value}} n'est pas un e-mail valide",
            field_valid_iban: "{{value}} n'est pas un IBAN correct",
            unexpected_error: "Une erreur inattendue s'est produite",
        },
    },
    auth: {
        actions: {
            sign_in: "Connexion",
            logout: "Déconnexion",
            reset_password: "Réinitialiser le mot de passe",
        },
        fields: {
            email: "",
            password: "Mot de passe",
            new_password: "Nouveau mot de passe",
            confirm_new_password: "Confirmer le nouveau mot de passe",
        },
        labels: {
            forgot_password: "Mot de passe oublié?",
            forgot_password_subheading:
                "Entrez votre e-mail pour réinitialiser votre mot de passe",
        },
        feedback: {
            unauthorized: "La session est invalide",
            passwords_does_not_match: "Les mots de passe ne correspondent pas",
        },
    },

    error404: {
        labels: {
            heading:
                "Désolé, nous n'arrivons pas à trouver la page que vous recherchez.",
            subheading:
                "Il est possible qu'il y ait eu une faute d'orthographe dans l'URL que vous avez saisie, ou que la page que vous recherchez n'existe plus.",
        },
    },

    home: {
        title: "Début",
    },
    cart: {
        title: "Chariot",
    },
    checkout: {
        title: "Détails de la réservation",
        continueShopping: "Continuer mes achats",
        noItemsInTheCart: "Il n'y a pas d'articles dans le panier",
        total: "Totale",
        total_without_taxes: "Total sans taxes",
        total_taxes: "Taxes",
        discount: "Remise",
        total_to_pay: "Total à payer",
        confirm_booking: "Confirmer la réservation",
        clear_basket: "Vider le panier",
        total_nights: "Nuits",
        nights: "nuits",
        night_of: "Nuit de",
        from: "De",
        to: "À",
        delete: "Supprimer",
        book_price_detail: "Détail du prix du livre",
    },
    booking: {
        title: "Confirmation et paiement",
        personalInformation: "Informations personnelles\n",
        name: "Nom",
        surname: "Nom de famille",
        email: "Email",
        telephone: "Téléphone",
        invalidTelephone: "Veuillez saisir un numéro de téléphone valide",
        roomsWithOverbookingDisclaimer: "Quand la réservation sera finaliser, nous vous contacterons dans les plus bref délais, pour l'instant cette réservation reste en attente de confirmation pour les jours suivants:",
        paymentRequiredDisclaimer: "Someone from the Love Events team will contact you shortly to make the payment.",
        roomsToBePaidAtTheHotelDisclaimer: "Cette réservation contient des chambres à payer directement à hotel, et selon la politique de l'hôtel, une carte de crédit est necessaire comme garantie.",
        creditCardInformation: "Informations de carte de crédit",
        creditCardHolder: "Titulaire de la carte de crédit",
        creditCardNumber: "Numéro de la carte de crédit",
        invalidCreditCardNumber: "Veuillez entrer un numéro de carte de crédit valide",
        expiration: "Expiration",
        invalidCreditCardExpiration: "La date d'expiration de la carte de crédit doit être postérieure à la date de la réservation",
        roomDetails: "Détails chambres",
        room: "Chambre",
        guests: "Les hôtes",
        guestN: "Hôte {{number}}",
        child: "Enfant",
        checkInTime: "Heure d'arrivée â l'hôtel",
        iDontKnow: "Je ne le sais pas",
        comments: "Commentaires",
        acceptTerms: "J'ai lu et j'accepte ",
        termsAndConditions: "les termes et conditions",
        submit: "Faire une réservation",
        formContainsErrors: "Le formulaire contient des erreurs. Veuillez les vérifier.",
        errorWhenMakingTheBookingHeading: "Erreur lors de la réservation",
        errorWhenMakingTheBookingBody: "Une erreur s'est produite lors de la réservation. Veuillez réessayer. Si le problème persiste, contactez-nous.",
        prebookingHasExpiredError: "La pré-réservation a expiré après 15 minutes de démarrage du processus sans le terminer. Veuillez recommencer le processus de réservation",
        bookingDetails: "Booking details",
    },
    bookOk: {
        yourBooking: "Votre réservation",
        bookingConfirmed: "Votre réservation a été confirmée",
        roomsWithOverbookingDisclaimer: "Une fois la réservation effectuée, nous vous contacterons dès que possible car cette réservation doit être approuvée",
        rememberPay: "N'oubliez pas que lorsque vous arriverez à l'hôtel, vous devrez payer:  ",
        confirmationNumber: "Numéro de confirmation",
        nameAndSurnames: "Nom et prénoms",
        email: "E-mail de contact",
        telephone: "Téléphone de contact",
        hotelDetails: "Détails de l'hôtel",
        event: "Événement",
        city: "Ville",
        hotel: "Hôtel",
        address: "Address",
        payMethod: "Méthode de paiement",
        payOnHotel: "Payer à l'hôtel",
        roomDetails: "Détails de la chambre",
        roomType: "Type de chambre",
        occupancy: "Occupation",
        arrivalHour: "Heure d'arrivée",
        guest: "Invité",
        total: "Totale"
    }
};

export default translations;
