import React, { FC } from "react";
import useCustomerContext from "modules/customers/hooks/useCustomerContext";
import { StarIcon } from "@heroicons/react/24/solid";

export interface HotelsMapPinsProps {
    isSelected?: boolean;
    lat: number;
    lng: number;
}

const EventMapPin: FC<HotelsMapPinsProps> = ({
    isSelected
}) => {
    const { customer } = useCustomerContext();

    return (
        <div
            className={`nc-AnyReactComponent relative`}
            data-nc-id="AnyReactComponent"
        >
            <span
              className={`flex px-2 py-1 rounded-lg bg-white dark:bg-neutral-900 text-sm font-semibold items-center justify-center min-w-max shadow-lg hover:bg-neutral-900 hover:text-white dark:hover:bg-white dark:hover:text-neutral-900 transition-colors ${
                  isSelected
                      ? "bg-neutral-900 text-white dark:bg-white dark:text-neutral-900"
                      : ""
              }`}
            >
                {customer.type === 2
                    ? <img alt="event" className="w-[18px] h-[18px]" src="/poker_maps.png" />
                    : <StarIcon className="w-[18px] h-[18px] text-orange-500" />
                }
            </span>
        </div>
    );
};

export default EventMapPin;
