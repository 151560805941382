import React, { FC } from "react";
import useEventGet from "../../../modules/events/hooks/useEventGet";
import AsideEventCities from "./AsideEventCities";

export interface AsideEventsSectionProps {
    eventId: number;
}

const AsideEvent: FC<AsideEventsSectionProps> = ({
    eventId,
}) => {

    const customerEventQuery = useEventGet(eventId);
    const {data: customerEvent} = customerEventQuery;

    return (
        <>
            <h1 className="font-semibold text-2xl ">{customerEvent?.name}</h1>
            <ul className="space-y-5 mt-10 mb-16">
                <AsideEventCities eventId={eventId} />
            </ul>
        </>
    );
};

export default AsideEvent;
