import React, {FC} from "react";
import {StarIcon} from "@heroicons/react/24/solid";
import Hotel from "../../modules/hotels/types/Hotel";

export interface HotelMainInfoCardProps {
    hotel: Hotel;
}

const HotelMainInfoCard: FC<HotelMainInfoCardProps> = ({
    hotel,
}) => {
    return (
            <div className="listingSection__wrap !space-y-6">
                {/* 1 */}
                <div className="flex items-end">
                    {[...Array(hotel.stars)].map((value, index) => <StarIcon className="w-[18px] h-[18px] text-orange-500" key={index} />)}
                </div>

                {/* 2 */}
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                    {hotel.name}
                </h2>

                {/* 3 */}
                <div className="flex items-center space-x-4">
                    <span>
                        <i className="las la-map-marker-alt"></i>
                        <span className="ml-1"> {hotel.address}</span>
                    </span>
                </div>
            </div>
    );
};

export default HotelMainInfoCard;
