import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import HotelImage from "../types/HotelImage";

type Output = HotelImage[];

export default function useHotelImagesGetAll (
    hotelId: any,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["hotel-images-by-hotel", hotelId],
        () => {
            return client.get<any>(`hotel-images/hotel/`+hotelId);
        },
        options
    );
}