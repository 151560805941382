import React, {FC} from "react";
import useCustomerContext from "modules/customers/hooks/useCustomerContext";
import useEventsByCustomerGet from "modules/events/hooks/useEventsByCustomerGet";
import Event from "modules/events/types/Event";
import AsideEventsSection from "../Event/AsideEventsSection";
import MainEvent from "./MainEvent";

const MainEventsSection: FC = () => {
    const {customer} = useCustomerContext();
    const {data} = useEventsByCustomerGet(customer.id);

    const renderEvent = (data: Event) => {
        return (
            <MainEvent event={data} key={data.id}/>
        );
    };

    return (
        <div
            className={`nc-SectionOurFeatures relative flex flex-col lg:flex-row`}
            data-nc-id="SectionOurFeatures"
        >
            <div className="flex-grow">
                {data ? data.map((customerEvent) => renderEvent(customerEvent))
                    : null}
            </div>
            <AsideEventsSection customerId={customer.id}/>
        </div>
    );
};

export default MainEventsSection;
