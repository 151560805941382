import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import CustomerTranslation from "../types/CustomerTranslation";

export default function useCustomerTranslationGet (
    customerId: number,
    languageId: number,
    options?: CacheQueryOpts
): CacheQueryOutput<CustomerTranslation> {
    const client = useAPIClient();
    return useCacheQuery<CustomerTranslation>(
        ["customer-translation", {
            "customerId": customerId,
            "languageId": languageId
        }],
        () => {
            return client.get<any>(`translations/customer`, {
                "customerId": customerId,
                "languageId": languageId
            });
        },
        options
    );
}