import React from "react";
import useTranslate from "modules/core/languages/hooks/useTranslate";
import useCustomerContext from "modules/customers/hooks/useCustomerContext";

interface FooterProps {
    content: string;
}

const Footer = ({content}: FooterProps) => {
    const { theme } = useCustomerContext();
    const t = useTranslate();

    const bgClass = theme ? ' bg-secondary-900' : '';
    const textClass = theme ? 'text-secondary-50' : '';

    return (
        (
            <div
                className={`nc-Footer relative py-2 lg:py-4 border-t border-neutral-200 dark:border-neutral-700 text-center${bgClass}`}>
                <div className={`${textClass} mb-3`} dangerouslySetInnerHTML={{__html: content}} />
                <a className={`${textClass} underline`} href={"/legal-notice"}>{t("footer.legalNotice")}</a> <span className={textClass}>|</span>
                <a className={`${textClass} underline`} href={"/privacy-policy"}> {t("footer.privacyPolicy")}</a> <span className={textClass}>|</span>
                <a className={`${textClass} underline`} href={"/cookies-policy"}> {t("footer.cookiesPolicy")}</a> <span className={textClass}>|</span>
                <a className={`${textClass} underline`} href="https://fdigital.es/honos-abogados/" target="_blank" rel="noreferrer"> {t("footer.complaintChannel")}</a>
            </div>
        )
    );
};

export default Footer;
