import HeroSearchForm from "modules/booking/components/HeroSearchForm/HeroSearchForm";
import useCustomerContext from "../../modules/customers/hooks/useCustomerContext";

const SectionSearchForm = () => {
    const { customer } = useCustomerContext();
    const bgClass = customer.type == 2 ? 'bg-secondary-900' : '';

    return <div className={`flex justify-center pb-8 mb-2 ${bgClass}`}><HeroSearchForm /></div>
}

export default SectionSearchForm;
