import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useDataSave, {DataSaveType} from "../../core/services/query/hooks/useDataSave";
import useCacheRemove from "../../core/services/query/hooks/useCacheRemove";

export interface DeleteRoomInput {
    prebookingId: string;
}

export default function useDeleteAllBasketItems (): DataSaveType<DeleteRoomInput> {
    const client = useAPIClient();
    const removeCache = useCacheRemove();

    return useDataSave<DeleteRoomInput>(
        input => client.delete("delete-all-prebooking-room", input),
        {
            onSuccess: () => {
                removeCache(["basket-items"]);
                removeCache(["basket-count"]);
                removeCache(["prebooking-by-internal-id"]);
            },
        }
    );
}