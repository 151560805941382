import React from "react";
import Textarea, { TextareaProps } from "modules/ui/shared/Textarea/Textarea";
import Label from "modules/ui/components/Label/Label";
import InputError from "modules/ui/shared/InputError/InputError";

export interface TextAreaFieldProps extends TextareaProps {
    label?: string;
    error?: string;
}

const TextAreaField = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
    (
        {
            label,
            error,
            ...inputProps
        },
        ref
    ) => {
        return (
            <div className="space-y-1">
                {label && <Label isInvalid={!!error}>{label}</Label>}
                <Textarea ref={ref} isInvalid={!!error} {...inputProps} />
                {error && <InputError>{error}</InputError>}
            </div>
        );
    }
);

export default TextAreaField;
