import React from "react";
import BgGlassmorphism from "modules/ui/components/BgGlassmorphism/BgGlassmorphism";
import PublicLayout from "../../modules/ui/components/PublicLayout";
import PrivacyPolicyText from "./PrivacyPolicyText";
import useCustomerContext from "modules/customers/hooks/useCustomerContext";

function PrivacyPolicy() {
    const { customerTranslation } = useCustomerContext();

    return (
        <PublicLayout>
            <div className="nc-PageHome relative overflow-hidden">
                {/* GLASSMOPHIN */}
                <BgGlassmorphism />

                <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                    {/* MAIN SECTION */}
                    <div className="relative py-16">
                        <PrivacyPolicyText content={customerTranslation.privacyPolicy}/>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
}

export default PrivacyPolicy;
