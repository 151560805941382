import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import BookPriceDetail from "../types/BookPriceDetail";

type Output = BookPriceDetail[];

export default function useGetBookPriceDetail (
    eventHotelRoomId: number,
    bookFrom: string,
    bookTo: string,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["prebooking-items-get-price-detail", eventHotelRoomId],
        () => {
            return client.get<any>(`prebooking-items-get-price-detail`, {
                eventHotelRoomId,
                bookFrom,
                bookTo
            });
        },
        options
    );
}
