import React from "react";
import Select, { SelectProps } from "modules/ui/shared/Select/Select";
import Label from "modules/ui/components/Label/Label";
import InputError from "modules/ui/shared/InputError/InputError";

export interface SelectFieldProps extends SelectProps {
    label?: React.ReactNode;
    error?: string;
}

const SelectField = React.forwardRef<HTMLSelectElement, SelectFieldProps>(
    (
        {
            label,
            error,
            ...inputProps
        },
        ref
    ) => {
        return (
            <div className="space-y-1">
                {label && <Label isInvalid={!!error}>{label}</Label>}
                <Select ref={ref} isInvalid={!!error} {...inputProps} />
                {error && <InputError>{error}</InputError>}
            </div>
        );
    }
);

export default SelectField;
