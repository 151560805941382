import React from "react";

export interface CheckboxProps {
    label?: React.ReactNode;
    subLabel?: string;
    className?: string;
    name: string;
    defaultChecked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isInvalid?: boolean;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            subLabel = "",
            label = "",
            name,
            className = "",
            defaultChecked,
            onChange,
            isInvalid = false,
        },
        ref
    ) => {
        const borderClass = isInvalid ?
            "border-red-400 dark:border-red-800"
            : "border-neutral-500";

        const labelColorClass = isInvalid ? "text-red-600 dark:text-red-400" : "text-neutral-900 dark:text-neutral-100";

        return (
            <div className={`flex text-sm sm:text-base ${className}`}>
                <input
                    ref={ref}
                    id={name}
                    name={name}
                    type="checkbox"
                    className={`focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500 ${borderClass}`}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                />
                {label && (
                    <label
                        htmlFor={name}
                        className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                        <span className={labelColorClass}>
                            {label}
                        </span>
                        {subLabel && (
                            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
                                {subLabel}
                            </p>
                        )}
                    </label>
                )}
            </div>
        );
    }
);

export default Checkbox;
