import React from "react";
import {useParams} from "react-router-dom";
import useEventCityGet from "../../modules/eventCities/hooks/useEventCityGet";
import PublicLayout from "../../modules/ui/components/PublicLayout";
import BgGlassmorphism from "../../modules/ui/components/BgGlassmorphism/BgGlassmorphism";
import HotelMainInfoCard from "./HotelMainInfoCard";
import useCustomerContext from "../../modules/customers/hooks/useCustomerContext";
import useHotelDetailsGet from "../../modules/hotels/hooks/useHotelDetailsGet";
import HotelImagesGallery from "./HotelImagesGallery";
import HotelSectionHero from "./HotelSectionHero";
import HotelAmenitiesCard from "./HotelAmenitiesCard";
import HotelLocationCard from "./HotelLocationCard";
import HotelInformationAndExtrasCard from "./HotelInformationAndExtrasCard";
import HotelCancellationPolicyCard from "./HotelCancellationPolityCard";
import HotelRoomCard from "./HotelRoomCard";
import useHotelImagesGetAll from "../../modules/hotels/hooks/useHotelImagesGetAll";
import useHotelFacilitiesGetAll from "../../modules/hotels/hooks/useHotelFacilitiesGetAll";
import useHotelCategoriesGetAll from "../../modules/hotels/hooks/useHotelCategoriesGetAll";
import useHotelGetRoomsInfo from "../../modules/hotels/hooks/useHotelGetRoomsInfo";
import useBookingSearchContext from "../../modules/booking/hooks/useBookingSearchContext";
import useHotelExtraInfoGet from "../../modules/hotels/hooks/useHotelExtraInfoGet";
import HotelExtraInfoCard from "./HotelExtraInfoCard";
import Loader from "modules/ui/shared/Loader/Loader";

function PageHotel() {
    const {eventCityId} = useParams();
    const {hotelId} = useParams();
    const {languageId} = useCustomerContext();
    const {eventHotelId} = useParams();
    const { dates: bookDates } = useBookingSearchContext();

    const bookFrom = bookDates.startDate?.format("DD/MM/YYYY") || null;
    const bookUntil = bookDates.endDate?.format("DD/MM/YYYY") || null;

    const eventCityQuery = useEventCityGet(eventCityId);
    const {data: eventCity} = eventCityQuery;

    const hotelInfoQuery = useHotelDetailsGet(hotelId, languageId, eventCityId);
    const {data: hotelInfo} = hotelInfoQuery;

    const hotelImagesQuery = useHotelImagesGetAll(hotelId);
    const {data: hotelImages} = hotelImagesQuery;

    const hotelFacilitiesQuery = useHotelFacilitiesGetAll(hotelId, languageId);
    const {data: hotelFacilities} = hotelFacilitiesQuery;

    const hotelCategoriesQuery = useHotelCategoriesGetAll(hotelId, languageId);
    const {data: hotelCategories} = hotelCategoriesQuery;

    const hotelRoomsQuery = useHotelGetRoomsInfo(eventHotelId, languageId, eventCityId, bookFrom, bookUntil);
    const {data: hotelRooms} = hotelRoomsQuery;

    const hotelExtraInfoQuery = useHotelExtraInfoGet(hotelId, languageId);
    const {data: hotelExtraInfo} = hotelExtraInfoQuery;

    return (
        <PublicLayout>
            {hotelInfo ?
                    <div className="nc-PageHome relative overflow-hidden">
                        {/* GLASSMOPHIN */}
                        <BgGlassmorphism/>

                        {/* SECTION HERO */}
                        {eventCity ?
                            <HotelSectionHero
                                mainImage={process.env.REACT_APP_API_CITY_BANNERS_STORAGE + eventCity.banner}
                            />
                            : <Loader centerHorizontally />
                        }

                        {/* GALLERY */}
                        {hotelImages ?
                            <HotelImagesGallery hotelId={hotelInfo.hotel.id} hotelImages={hotelImages}/>
                            : <Loader centerHorizontally />
                        }

                        {/* MAIn */}
                        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
                            {/* CONTENT */}
                            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10 mb-10">
                                {hotelInfo ?
                                    <HotelMainInfoCard hotel={hotelInfo.hotel}/>
                                    : <Loader centerHorizontally />
                                }
                                {hotelInfo ?
                                    <HotelCancellationPolicyCard eventHotelTranslation={hotelInfo.hotel_event_info}/>
                                    : <Loader centerHorizontally />
                                }
                                {hotelCategories ?
                                    <HotelAmenitiesCard hotelCategories={hotelCategories}/>
                                    : <Loader centerHorizontally />
                                }
                                {hotelFacilities ?
                                    <HotelInformationAndExtrasCard hotelFacilities={hotelFacilities}/>
                                    : <Loader centerHorizontally />
                                }
                                {hotelExtraInfo ?
                                    <HotelExtraInfoCard hotelExtraInfo={hotelExtraInfo}/>
                                    : <Loader centerHorizontally />
                                }
                                {hotelInfo ?
                                    <HotelLocationCard hotel={hotelInfo.hotel}/>
                                    : <Loader centerHorizontally />
                                }
                            </div>

                            {/* SIDEBAR */}
                            <div className="flex-grow mt-14 lg:mt-0">
                                <div className="sticky top-28">
                                    {hotelRooms ?
                                        hotelRooms.map((item) => (
                                            <HotelRoomCard eventCityId={eventCityId} hotelRoom={item} key={item.id}/>
                                        ))
                                        : <Loader centerHorizontally />
                                    }
                                </div>
                            </div>

                        </main>
                    </div>
                : <Loader centerHorizontally />}
        </PublicLayout>
    );
}

export default PageHotel;
