import React from "react";
import ButtonClose from "modules/ui/shared/ButtonClose/ButtonClose";

export interface AlertProps {
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
  children: React.ReactNode;
  closable?: boolean;
  className?: string;
  onClose?: () => void;
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
  closable = true,
  className,
  onClose,
}) => {
  let classes = containerClassName;
  switch (type) {
    case "default":
      classes += " text-black bg-neutral-900";
      break;
    case "info":
      classes += " bg-blue-200 text-blue-900";
      break;
    case "success":
      classes += " bg-green-200 text-green-900";
      break;
    case "error":
      classes += " bg-red-200 text-red-900";
      break;
    case "warning":
      classes += " bg-orange-200 text-orange-900";
      break;
    default:
      break;
  }

  return (
    <div
      className={`ttnc-alert relative flex items-center text-paragraph-base px-6 pt-4 pb-3 rounded-lg ${classes} ${className}`}
    >
      <i className="pe-7s-info text-2xl mr-2"></i>
      {children}
      {closable && <ButtonClose onClick={onClose} className="absolute top-4 right-6" />}
    </div>
  );
};
