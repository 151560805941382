import React from "react";
import useEventCityByEventAndLanguageGet from "modules/eventCities/hooks/useEventCityByEventAndLanguageGet";
import useCustomerContext from "modules/customers/hooks/useCustomerContext";

interface CustomerDescriptionProps {
    cityEventId: any;
}

const CityEventDescription = ({
    cityEventId,
}: CustomerDescriptionProps) => {
    const { languageId } = useCustomerContext();

    const eventCityQuery = useEventCityByEventAndLanguageGet(cityEventId, languageId);
    const { data: eventTranslation } = eventCityQuery;

    return (
        (eventTranslation ?
                <span dangerouslySetInnerHTML={{__html: eventTranslation.information}}/>
                : null
        )
    );
}

export default CityEventDescription;
