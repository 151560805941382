import React, { FC } from "react";

export interface HotelSectionHeroProps {
    mainImage: string;
}

const HotelSectionHero: FC<HotelSectionHeroProps> = ({
    mainImage,
}) => {
    return (
        <div
            className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative lg:pb-16`}
            data-nc-id="SectionHero"
        >
            <div className="flex flex-col lg:flex-row lg:items-center lg:h-[350px] overflow-hidden">
                <div className="flex-grow">
                    <img className="w-full" src={mainImage} alt="hero"/>
                </div>
            </div>

        </div>
    );
};

export default HotelSectionHero;
