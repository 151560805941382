import React, { FC } from "react";
import useEventsByCustomerGet from "../../../modules/events/hooks/useEventsByCustomerGet";
import AsideEvent from "./AsideEvent";
import Event from "../../../modules/events/types/Event";

export interface AsideEventsSectionProps {
    customerId: number;
}

const AsideEventsSection: FC<AsideEventsSectionProps> = ({
    customerId,
}) => {

    const { data } = useEventsByCustomerGet(customerId);

    const renderAsideEvent = (data: Event) => {
        return <AsideEvent eventId={data.id} key={data.id} />
    };

    return (
        <div
            className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-1/3 lg:pl-16`}
        >
            {data ? data.map((customerEvent) => renderAsideEvent(customerEvent))
            : null }
        </div>
    );
};

export default AsideEventsSection;
