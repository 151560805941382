import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import HotelInfo from "../types/HotelInfo";

export default function useHotelDetailsGet (
    hotelId: any,
    languageId: number,
    eventCityId: any,
    options?: CacheQueryOpts
): CacheQueryOutput<HotelInfo> {
    const client = useAPIClient();
    return useCacheQuery<HotelInfo>(
        ["hotel-details", hotelId, languageId],
        () => {
            return client.get<any>(`hotel-details/` + hotelId, {
                "languageId": languageId,
                "eventCityId": eventCityId
            });
        },
        options
    );
}