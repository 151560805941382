import React, { FC } from "react";
import GallerySlider from "modules/ui/components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import Badge from "modules/ui/shared/Badge/Badge";
import HotelDetail from "../../../modules/hotels/types/HotelDetail";
import HotelImage from "../../../modules/hotels/types/HotelImage";
import {formatNumber} from "../../../modules/common/utils/number";

export interface HotelMapCardProps {
    hotelDetail: HotelDetail,
    className?: string;
    size?: "default" | "small";
    cityEventId: number;
}

const HotelMapCard: FC<HotelMapCardProps> = ({
    hotelDetail,
    size = "default",
    className = "",
    cityEventId
}) => {

    const renderSliderGallery = (hotelDetail:HotelDetail) => {
        return (
            <div className="relative w-full">
                <GallerySlider
                    uniqueID={`StayCard_${hotelDetail.hotel_id}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={
                        hotelDetail.images.map((image:HotelImage) => (
                            (process.env.REACT_APP_API_HOTEL_STORAGE as string) + hotelDetail.hotel_id + "/" + image.file
                        ))
                    }
                    href={"/event-city/" + cityEventId + "/" + hotelDetail.event_hotel_id + "/hotel/" + hotelDetail.hotel_id}
                />
            </div>
        );
    };

    const renderContent = (hotelDetail:HotelDetail) => {
        return (
            <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
                <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {/*AQUÍ PONER LAS ESTRELLAS DEL HOTEL*/}
              <Badge name="****" color="green" />
          </span>
                    <div className="flex items-center space-x-2">
                        {/*{isAds && <Badge name="ADS" color="green" />}*/}
                        <h2
                            className={` font-medium capitalize ${
                                size === "default" ? "text-lg" : "text-base"
                            }`}
                        >
                            <span className="line-clamp-1">{hotelDetail.name}</span>
                        </h2>
                    </div>
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        {size === "default" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                        )}
                        <span className="">{hotelDetail.address}</span>
                    </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                <div className="flex justify-between items-center">
                  <div className="text-base font-semibold">
                    {formatNumber(hotelDetail.suggestedRoom.total_price)} {' '}
                    <span dangerouslySetInnerHTML={{ __html:hotelDetail.suggestedRoom?.currency}} />
                  </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="StayCard"
        >
            {renderSliderGallery(hotelDetail)}
            <Link to={"/event-city/" + cityEventId + "/" + hotelDetail.event_hotel_id + "/hotel/" + hotelDetail.hotel_id}>{renderContent(hotelDetail)}</Link>
        </div>
    );
};

export default HotelMapCard;
