import React, {useEffect} from "react";
import BgGlassmorphism from "modules/ui/components/BgGlassmorphism/BgGlassmorphism";
import PublicLayout from "modules/ui/components/PublicLayout";
import SectionHero from "modules/ui/components/SectionHero/SectionHero";
import useCustomerContext from "modules/customers/hooks/useCustomerContext";
import CustomerDescription from "./Customer/CustomerDescription";
import MainBackgroundSection from "./MainBackgroundSection";
import MainEventsSection from "./MainEventsSection";
import useCityListForActiveEventsGet from "../../modules/eventCities/hooks/useCityListForActiveEventsGet";
import { useNavigate } from "react-router-dom";

function PageHome() {
    const { customer, languageId } = useCustomerContext();

    const { data: eventCities } = useCityListForActiveEventsGet(customer.id, languageId);

    const navigate = useNavigate();

    useEffect(() => {
        // If there is only one event city
        // skip the home page and redirect
        // to the event city page
        if (eventCities?.length === 1) {
            navigate(`/event-city/${eventCities[0].id}`);
        }
    }, [eventCities]);

    return (
        <PublicLayout>
            <div className="nc-PageHome relative overflow-hidden">
                {/* GLASSMOPHIN */}
                <BgGlassmorphism />

                {/* SECTION HERO */}
                <SectionHero mainImage={process.env.REACT_APP_API_CUSTOMER_MAIN_IMAGE_STORAGE + customer.main_image} className="pb-12" />

                <div className="container relative space-y-24 mb-24 lg:space-y-10 lg:mb-28">
                    <CustomerDescription />
                    <div className="relative pt-16">
                        <MainBackgroundSection />
                        <MainEventsSection />
                    </div>
                </div>
            </div>
        </PublicLayout>
  );
}

export default PageHome;
