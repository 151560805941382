import React, { FC, useState } from "react";
import GoogleMapReact from "google-map-react";
import ButtonClose from "../../../modules/ui/shared/ButtonClose/ButtonClose";
import Checkbox from "../../../modules/ui/shared/Checkbox/Checkbox";
import HotelGridCard from "./HotelGridCard";
import HotelsMapPins from "./HotelsMapPins";
import useCustomerContext from "../../../modules/customers/hooks/useCustomerContext";
import useEventCityHotelsDetailsGet from "../../../modules/eventCities/hooks/useEventCityHotelsDetailsGet";
import HotelDetail from "../../../modules/hotels/types/HotelDetail";
import EventMapPin from "./EventMapPin";
import EventCity from "../../../modules/eventCities/types/EventCity";
import useBookingSearchContext from "../../../modules/booking/hooks/useBookingSearchContext";
import Loader from "modules/ui/shared/Loader/Loader";

export interface HotelsMapGridSectionProps {
    eventCity: EventCity,
    bookFrom?: any,
    bookUntil?: any
}

const HotelsMapGridSection: FC<HotelsMapGridSectionProps> = ({
    eventCity,
    bookFrom,
    bookUntil
}) => {
    const { languageId } = useCustomerContext();
    const { rooms } = useBookingSearchContext();

    const minimumOccupancyArr = rooms.map(room => (room.guestAdults || 0) + (room.guestChildren || 0))
    const minimumOccupancy = minimumOccupancyArr.reduce(function(prev, curr) {
        return prev > 0 && prev < curr ? prev : curr;
    }, 0);

    const eventCityHotelDetailsQuery = useEventCityHotelsDetailsGet(
        eventCity.id,
        languageId,
        bookFrom,
        bookUntil,
        minimumOccupancy || 1
    );

    const { data: hotelsDetails } = eventCityHotelDetailsQuery;

    const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
    const [showFullMapFixed, setShowFullMapFixed] = useState(false);

    return (
        <div>
            <div className="relative flex min-h-screen">
                {/* CARDSSSS */}
                <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
                    <div className="grid grid-cols-1 gap-8">

                        {hotelsDetails ? hotelsDetails.map((item:HotelDetail) => (
                            <div
                                key={item.hotel_id}
                                onMouseEnter={() => setCurrentHoverID((_) => item.hotel_id)}
                                onMouseLeave={() => setCurrentHoverID((_) => -1)}
                            >
                                <HotelGridCard hotelDetail={item} cityEventId={eventCity.id} key={"hotel_" + item.hotel_id} />
                            </div>
                        )): <Loader centerHorizontally /> }
                    </div>
                </div>

                {!showFullMapFixed && (
                    <div
                        className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
                        onClick={() => setShowFullMapFixed(true)}
                    >
                        <i className="text-lg las la-map"></i>
                        <span>Show map</span>
                    </div>
                )}

                {/* MAPPPPP */}
                <div
                    className={`xl:flex-grow xl:static xl:block ${
                        showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
                    }`}
                >
                    {showFullMapFixed && (
                        <ButtonClose
                            onClick={() => setShowFullMapFixed(false)}
                            className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                        />
                    )}

                    <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
                        <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
                            <Checkbox
                                className="text-xs xl:text-sm"
                                name="xx"
                                label="Search as I move the map"
                            />
                        </div>

                        {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
                        {hotelsDetails && hotelsDetails.length > 0 && eventCity ?
                        <GoogleMapReact
                            defaultZoom={14}
                            defaultCenter={hotelsDetails[0].map}
                            bootstrapURLKeys={{
                                key: (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string),
                            }}
                            yesIWantToUseGoogleMapApiInternals
                        >
                            <EventMapPin
                                isSelected={false}
                                key={eventCity.event_id}
                                lat={eventCity.lat}
                                lng={eventCity.lng}
                            />
                            {hotelsDetails ? hotelsDetails.map((item:HotelDetail) => (
                                item.available_book && (
                                <HotelsMapPins
                                    hotelDetail={item}
                                    isSelected={currentHoverID === item.hotel_id}
                                    key={item.hotel_id}
                                    lat={item.lat}
                                    lng={item.lng}
                                    cityEventId={eventCity.id}
                                />
                                )
                            )) : null }
                        </GoogleMapReact>
                        : null }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HotelsMapGridSection;
