import ca from "./translations/ca";
import es from "./translations/es";
import en from "./translations/en";
import fr from "./translations/fr";
import ru from "./translations/ru";
import it from "./translations/it";

export const languageResources = {
    ca: {
        translation: ca,
    },
    es: {
        translation: es,
    },
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
    it: {
        translation: it,
    },
    ru: {
        translation: ru,
    }
};

export const availableLanguages = Object.keys(languageResources);
export const availableLanguagesData = [
    {
        code: 'es',
        name: 'Español'
    },
    {
        code: 'en',
        name: 'Inglés'
    },
    {
        code: 'fr',
        name: 'Francés'
    },
    {
        code: 'it',
        name: 'Italiano'
    },
    {
        code: 'ru',
        name: 'Ruso'
    }
];
export const defaultLanguage = "es";
