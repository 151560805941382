import { useCallback } from "react";
import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import RoomAvailability from "../types/RoomAvailability";

export default function useCheckAvailability () {
    const client = useAPIClient();

    return useCallback((eventHotelRoomId: number,
                        quantity: number,
                        dateFrom?: string,
                        dateTo?: string,) => {
        return client.get<RoomAvailability>(`prebooking-room/` + eventHotelRoomId + `/availability`, {
            quantity: quantity,
            dateFrom: dateFrom,
            dateTo: dateTo
        });
    }, [client])
}
