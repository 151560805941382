import React, { FC } from "react";
import MainCitiesHeader from "./../MainCitiesSection/MainCitiesHeader";
import MainCityCard from "./../MainCitiesSection/MainCityCard";
import useEventCitiesByEventGet from "../../../modules/eventCities/hooks/useEventCitiesByEventGet";
import EventCity from "../../../modules/eventCities/types/EventCity";
import Event from "../../../modules/events/types/Event";

export interface MainEventProps {
    event: Event;
}

const MainEvent: FC<MainEventProps> = ({
    event,
}) => {

    const { data } = useEventCitiesByEventGet(event.id);

    const renderCard = (data: EventCity) => {
        return <MainCityCard key={data.id} eventCity={data} />;
    };

    return (
        <div className="flex-grow">
            <div className="nc-SectionGridFeaturePlaces relative mb-20">
                <MainCitiesHeader
                    heading={event.name}
                />

                <div
                    className={`grid gap-6 md:gap-8 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1`}
                >
                    {data ? data.map((city) => renderCard(city))
                        : null }
                </div>
            </div>
        </div>
    );
};

export default MainEvent;
