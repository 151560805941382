import React, {useEffect, useMemo, useState} from "react";
import PublicLayout from "modules/ui/components/PublicLayout";
import { useForm, SubmitHandler } from "react-hook-form";
import Input from "../../modules/ui/shared/Input/Input";
import Label from "../../modules/ui/components/Label/Label";
import ButtonPrimary from "../../modules/ui/shared/Button/ButtonPrimary";
import useBookingSearchContext from "../../modules/booking/hooks/useBookingSearchContext";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import useCustomerContext from "../../modules/customers/hooks/useCustomerContext";
import useGetBasketItems from "../../modules/booking/hooks/useGetBasketItems";
import useBookSave from "../../modules/booking/hooks/useBookSave";
import {Link, useNavigate} from "react-router-dom";
import Select from "../../modules/ui/shared/Select/Select";
import {DIAL_CODES} from "../../modules/ui/data/dialCodes";
import InputField from "../../modules/ui/components/InputField/InputField";
import InputError from "../../modules/ui/shared/InputError/InputError";
import RoomDetail from "./RoomDetail";
import CheckboxField from "../../modules/ui/components/CheckboxField/CheckboxField";
import {Alert} from "../../modules/ui/shared/Alert/Alert";
import Badge from "../../modules/ui/shared/Badge/Badge";
import moment from "moment";
import {parseInt} from "lodash";
import BookingErrorModal from "./BookingErrorModal";
import Sidebar from "./Sidebar";
import useGetPrebooking from "../../modules/booking/hooks/useGetPrebooking";
import useHotelDetailsGet from "../../modules/hotels/hooks/useHotelDetailsGet";
import Loader from "../../modules/ui/shared/Loader/Loader";

type Guests = {
    eventHotelRoom: number,
    guestsByRooms: ({
        name: string,
        surname: string
    })[]
    arrivalHour: string,
    comments: string,
    prebookingRoomId: number
}

type Inputs = {
    name: string,
    surname: string,
    email: string,
    dial: string,
    telephone: string,
    guests: Guests[],
    conditions: boolean,
    cardName: string | null,
    cardNumber: string | null,
    cardDateMonth: string | null,
    cardDateYear: string | null,
};

function PageBooking() {
    const { prebookingId, setPrebookingId } = useBookingSearchContext();
    const t = useTranslate();
    const navigate = useNavigate();
    const { languageId } = useCustomerContext();

    const [creditCardExpirationError, setCreditCardExpirationError] = useState<string | null>(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const bookSave = useBookSave();

    const {data: basketItems} = useGetBasketItems(prebookingId, languageId);

    const hotelId = basketItems?.at(0)?.hotel_id;
    const eventCityId = basketItems?.at(0)?.event_city_id;
    const {data: hotelInfo} = useHotelDetailsGet(hotelId, languageId, eventCityId, {execute: !!hotelId && !!eventCityId});

    const prebookingQuery = useGetPrebooking(prebookingId);
    const {data: prebooking} = prebookingQuery;

    const form = useForm<Inputs>({mode: "onBlur"});
    const { register, handleSubmit, formState: { errors } } = form;

    const [creditCardExpirationMonth, creditCardExpirationYear] = form.watch(['cardDateMonth', 'cardDateYear']);

    const overBookingDates: string[] = useMemo(() => {
        if (!basketItems) return [];

        const dates: string[] = [];

        basketItems.forEach(basketItem => {
            if (basketItem.overbooking) {
                basketItem.overbooking.forEach(date => {
                    if (!dates.includes(date)) dates.push(date);
                });
            }
        });

        return dates;
    }, [basketItems]);

    const isDirectPayment = useMemo(() => {
        if (!basketItems) return false;

        return !!basketItems.find(basketItem => basketItem.direct_payment);
    }, [basketItems]);

    const isPaymentRequired = useMemo(() => {
        if (!basketItems) return false;

        return !!basketItems.find(basketItem => basketItem.payment_required);
    }, [basketItems]);

    useEffect(() => {
        if (!prebookingId || (basketItems && basketItems.length === 0))
            navigate('/checkout');
    }, [prebookingId, basketItems, navigate]);

    useEffect(() => {
        const bookFrom = basketItems?.at(0)?.book_from_to_front;
        if (!creditCardExpirationMonth || !creditCardExpirationYear || !bookFrom) return;

        const expirationDate = moment({
            year: parseInt(`20${creditCardExpirationYear}`),
            month: parseInt(creditCardExpirationMonth) - 1,
            day: 1,
            hour: 0,
            minute: 0,
            second: 0
        })

        if (moment(`${bookFrom} 00:00:00`, 'DD/MM/YYYY HH:mm:ss').isAfter(expirationDate)) {
            setCreditCardExpirationError(t('booking.invalidCreditCardExpiration'));
        } else {
            setCreditCardExpirationError(null);
        }
    }, [creditCardExpirationMonth, creditCardExpirationYear]);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        if (!!creditCardExpirationError) return;

        const dialParts = data.dial.split('-');
        const iso2 = dialParts[0];
        const dialCode = dialParts[1];

        bookSave.save(
            {
                prebookingInternalId: prebookingId,
                name: data.name,
                surname: data.surname,
                email: data.email,
                iso2,
                dialCode,
                telephone: data.telephone,
                guests: data.guests,
                conditions: data.conditions,
                cardName: data.cardName,
                cardNumber: data.cardNumber,
                cardDate: `${data.cardDateMonth}/${data.cardDateYear}`,
            }, {
                onSuccess: (bookingInternalId) => {
                    setPrebookingId(null);
                    navigate("/book-ok/" + bookingInternalId)
                },
                onError: () => {
                    setOpenErrorModal(true);
                }
            }
        );
    }

    const overbookingAlert = overBookingDates.length > 0 &&
        (
            <Alert type="warning" closable={false} containerClassName="mb-5">
                <div>
                    <p className="mb-2">{t("booking.roomsWithOverbookingDisclaimer")}</p>
                    <div className="space-x-1">
                        {overBookingDates.map(overBookingDate => <Badge key={overBookingDate} name={overBookingDate} color="yellow" />)}
                    </div>
                </div>
            </Alert>
        );

    const renderMain = () => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-2 px-0 sm:p-6 xl:p-8">
                <h2 className="text-3xl lg:text-4xl font-semibold">
                    {t("booking.title")}
                </h2>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                {overbookingAlert}

                <div>
                    <div className="mt-6">
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <h3 className="text-2xl font-semibold mb-5">{t("booking.personalInformation")}</h3>
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
                            <div className="lg:flex lg:space-x-5">
                                <div className="lg:w-1/2 mb-2">
                                    <InputField
                                        label={t("booking.name")}
                                        type="text"
                                        defaultValue=""
                                        {...register("name", { required: t("common.feedback.field_required") })}
                                        error={errors.name?.message}
                                    />
                                </div>
                                <div className="lg:w-1/2 mb-2">
                                    <InputField
                                        label={t("booking.surname")}
                                        type="text"
                                        defaultValue=""
                                        {...register("surname", { required: t("common.feedback.field_required") })}
                                        error={errors.surname?.message}
                                    />
                                </div>
                            </div>

                            <div className="lg:flex lg:space-x-5">
                                <div className="lg:w-1/2 mb-2">
                                    <InputField
                                        label={t("booking.email")}
                                        type="email"
                                        defaultValue=""
                                        {...register("email", { required: t("common.feedback.field_required") })}
                                        error={errors.email?.message}
                                    />
                                </div>
                                <div className="lg:w-1/2 space-y-1 mb-2">
                                    <Label isInvalid={!!errors.dial || !!errors.telephone}>{t("booking.telephone")}</Label>
                                    <div className="flex">
                                        <Select
                                            className="w-1/2 rounded-tr-none rounded-br-none"
                                            {...register("dial", { required: t("common.feedback.field_required") })}
                                            defaultValue="ES-34"
                                            isInvalid={!!errors.dial}
                                        >
                                            {DIAL_CODES.map(dialCode =>
                                                <option key={`${dialCode.code}-${dialCode.callingCode}`} value={`${dialCode.code}-${dialCode.callingCode}`}>
                                                    {dialCode.code} +{dialCode.callingCode}
                                                </option>
                                            )}
                                        </Select>
                                        <Input
                                            className="rounded-tl-none rounded-bl-none"
                                            type="text"
                                            defaultValue=""
                                            {...register("telephone", {
                                                required: t("common.feedback.field_required"),
                                                pattern: {
                                                    value: /^[0-9]{6,13}$/,
                                                    message: t("booking.invalidTelephone")
                                                }
                                            })}
                                            isInvalid={!!errors.telephone}
                                        />
                                    </div>
                                    {(errors.dial || errors.telephone) && <InputError>{errors.dial?.message || errors.telephone?.message}</InputError>}
                                </div>
                            </div>

                            <h3 className="text-2xl font-semibold mb-5 mt-5">{t("booking.creditCardInformation")}</h3>
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

                            {isDirectPayment && <Alert type="warning" containerClassName="mb-5" closable={false}>{t("booking.roomsToBePaidAtTheHotelDisclaimer")}</Alert>}
                            {isPaymentRequired && <Alert type="warning" containerClassName="mb-5" closable={false}>{t("booking.paymentRequiredDisclaimer")}</Alert>}

                            <div className="lg:flex lg:space-x-5 ">
                                <div className="lg:w-1/3 mb-2">
                                    <InputField
                                        label={t("booking.creditCardHolder")}
                                        defaultValue=""
                                        {...register("cardName", { required: t("common.feedback.field_required") })}
                                        error={errors.cardName?.message}
                                    />
                                </div>

                                <div className="lg:w-1/3 mb-2">
                                    <InputField
                                        label={t("booking.creditCardNumber")}
                                        defaultValue=""
                                        {...register(
                                            "cardNumber",
                                            {
                                                required: t("common.feedback.field_required"),
                                                pattern: {
                                                    value: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35[0-9]{3})[0-9]{11})$/,
                                                    message: t("booking.invalidCreditCardNumber")
                                                }
                                            }
                                        )}
                                        error={errors.cardNumber?.message}
                                    />
                                </div>

                                <div className="lg:w-1/3 space-y-1 mb-2 overflow-hidden">
                                    <Label isInvalid={!!errors.cardDateMonth || !!errors.cardDateYear || !!creditCardExpirationError}>
                                        {t("booking.expiration")}
                                    </Label>
                                    <div className="flex">
                                        <Select
                                            isInvalid={!!errors.cardDateMonth || !!creditCardExpirationError}
                                            className="w-1/2 rounded-tr-none rounded-br-none"
                                            {...register("cardDateMonth", {
                                                required: t("common.feedback.field_required"),
                                            })}>
                                            <option value="">MM</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </Select>
                                        <Input
                                            isInvalid={!!errors.cardDateYear || !!creditCardExpirationError}
                                            placeholder="YY"
                                            className="w-1/2 rounded-tl-none rounded-bl-none"
                                            maxLength={2}
                                            {...register(
                                                "cardDateYear",
                                                {
                                                    required: t("common.feedback.field_required"),
                                                    minLength: {
                                                        value: 2,
                                                        message: t("common.feedback.field_min_length", { length: 2 })
                                                    },
                                                    maxLength: {
                                                        value: 2,
                                                        message: t("common.feedback.field_max_length", { length: 2 })
                                                    },
                                                }
                                            )}
                                        />
                                    </div>
                                    {(errors.cardDateMonth || errors.cardDateYear || creditCardExpirationError) && (
                                        <InputError>{errors.cardDateMonth?.message || errors.cardDateYear?.message || creditCardExpirationError}</InputError>
                                    )}
                                </div>
                            </div>

                            <h3 className="text-2xl font-semibold mb-5 mt-5">{t("booking.roomDetails")}</h3>
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
                            {basketItems?.map((basketItem, index) => <RoomDetail key={basketItem.item_id} form={form} basketItem={basketItem} index={index} />)}

                            {hotelInfo?.hotel_event_info?.cancellation_policy ?
                                <>
                                    <h3 className="text-2xl font-semibold mb-5 mt-5">{t("hotelDetail.cancellationPolicy")}</h3>
                                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
                                    <div className="text-neutral-6000 dark:text-neutral-300">
                                        <span dangerouslySetInnerHTML={{__html: hotelInfo.hotel_event_info.cancellation_policy}}/>
                                    </div>
                                </>
                                : <Loader centerHorizontally />
                            }

                            <CheckboxField
                                className="mb-4 mt-4"
                                label={<>{t("booking.acceptTerms")} <Link to="/legal-notice" className="underline">{t("booking.termsAndConditions")}</Link></>}
                                {...register("conditions", { required: t("common.feedback.field_required") })}
                                error={errors.conditions?.message}
                            />

                            {overbookingAlert}

                            <div className="pt-4">
                                <ButtonPrimary loading={bookSave.isLoading} type="submit">{t("booking.submit")}</ButtonPrimary>
                            </div>

                            {((Object.keys(errors).length > 0) || !!creditCardExpirationError) && (
                                <Alert type="error" className="mt-6" closable={false}>{t("booking.formContainsErrors")}</Alert>
                            )}
                        </form>
                    </div>
                </div>

                <BookingErrorModal isOpen={openErrorModal} onClose={() => setOpenErrorModal(false)} error={bookSave.error?.message} />
            </div>
        );
    };

    const renderSidebar = () => <Sidebar basketItems={basketItems} prebooking={prebooking} />

    return (
        <PublicLayout>
            <div className={`nc-CheckOutPage`} data-nc-id="CheckOutPage">
                <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                    <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
                    {basketItems && (
                    <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
                    )}
                </main>
            </div>
        </PublicLayout>
    );
}

export default PageBooking;
