import React, { FC } from "react";
import useEventCitiesByEventGet from "../../../modules/eventCities/hooks/useEventCitiesByEventGet";
import EventCity from "../../../modules/eventCities/types/EventCity";
import AsideEventCityCard from "./AsideEventCityCard";

export interface AsideEventCitiesProps {
    eventId: number;
}

const AsideEventCities: FC<AsideEventCitiesProps> = ({
    eventId
}) => {

    const { data } = useEventCitiesByEventGet(eventId);

    const renderCard = (data: EventCity) => {
        return (
            <li className="space-y-4" key={data.id}>
                <AsideEventCityCard cityId={data.city_id} cityBanner={data.banner} eventCityId={data.id} />
            </li>
        )
    };

    return (
        <>
            {data ? data.map((city) => renderCard(city))
            : null }
        </>
    );
};

export default AsideEventCities;
