import React from "react";
import { useMutation } from "react-query";
import OperationStatus from "../types/OperationStatus";

export interface DataSaveOpts<OutputType = unknown, InputType = unknown> {
    onSuccess?: (data: OutputType, input: InputType) => void;
    onError?: (error: any, input: InputType) => void;
}

export interface DataSaveType<InputType = any, OutputType = any> {
    status: OperationStatus;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    isIdle: boolean;
    data?: OutputType;
    error: Error | null;
    save: (input: InputType, options?: DataSaveOpts<OutputType>) => void;
}

export default function useDataSave<InputType = any, OutputType = any>(
    callback: (input: InputType) => Promise<OutputType>,
    options: DataSaveOpts<OutputType, InputType> = {}
): DataSaveType<InputType, OutputType> {
    const {
        data,
        error,
        status,
        isLoading,
        isError,
        isSuccess,
        isIdle,
        mutate,
    } = useMutation<OutputType, Error, InputType>(callback, {
        onSuccess: options.onSuccess,
        onError: options.onError,
    });

    return {
        data,
        isLoading,
        isError,
        isSuccess,
        isIdle,
        error,
        status: getStatus(status),
        save: React.useCallback(
            (input, options = {}) =>
                mutate(input, {
                    onSuccess: options.onSuccess,
                    onError: options.onError,
                }),
            [mutate]
        ),
    };
}

const getStatus = (mutationStatus: string): OperationStatus => {
    switch (mutationStatus) {
        case "loading":
            return OperationStatus.Loading;
        case "error":
            return OperationStatus.Error;
        case "success":
            return OperationStatus.Success;
        case "idle":
        default:
            return OperationStatus.Idle;
    }
};
