const translations = {
    mainNav: {
        language: "Idioma",
        shoppingBasket: "Cistella",
    },
    bookingForm: {
        event: "Event",
        checkIn: "Check in",
        checkOut: "Check out",
        guest: "Guest",
        guests: "Guests",
        addDate: "Add date",
        addGuest: "Add",
        numberOfRooms: "Habitacions",
        adults: "Adults",
        children: "Nens",
    },
    common: {
        actions: {
            add: "Añadir",
            accept: "Aceptar",
            cancel: "Cancelar",
            edit: "Editar",
            delete: "Eliminar",
            submit: "Enviar",
            save: "Guardar",
            upload_file: "Subir archivo",
            search: "Buscar",
            show_more_filters: "Mostrar más filtros",
            hide_more_filters: "Ocultar más filtros",
            clear: "Limpiar",
            back: "Volver",
            goHome: "Ir a inicio",
            copy: "Copiar",
        },
        labels: {
            required_field: "Campo obligatorio",
            filters: "Filtros",
            none: "Ninguno",
            all: "Todos",
            creation_date: "Fecha de creación",
            error: "Error",
            administration: "Administración",
            actions: "Acciones",
            selected_items: "{{count}} elemento/s seleccionado/s",
            per_page: "Por página",
            sort_by: "Ordenar por",
            total: "Total",
            language: "Idioma",
        },
        feedback: {
            saved_data: "Datos guardados con éxito",
            no_data: "No hay datos",
            form_not_valid: "Hay errores en el formulario",
            field_required: "El campo es obligatorio",
            field_min: "El valor del campo no puede ser inferior a {{min}}",
            field_max: "El valor del campo no puede ser superior a {{max}}",
            field_min_length:
                "La longitud del campo no puede ser menor a {{length}}",
            field_max_length:
                "La longitud del campo no puede ser mayor a  {{length}}",
            field_valid_email: "{{value}} no es un correo electrónico válido",
            field_valid_iban: "{{value}} no es un IBAN correcto",
            unexpected_error: "Ha ocurrido un error inesperado",
        },
    },

    auth: {
        actions: {
            sign_in: "Iniciar sesión",
            logout: "Cerrar sesión",
            reset_password: "Restablecer contraseña",
        },
        fields: {
            email: "Correo electrónico",
            password: "Contraseña",
            new_password: "Nueva contraseña",
            confirm_new_password: "Confirmar nueva contraseña",
        },
        labels: {
            forgot_password: "¿Has olvidado la contraseña?",
            forgot_password_subheading:
                "Introduce tu correo electrónico para restablecer tu contraseña",
        },
        feedback: {
            unauthorized: "La sesión no es válida",
            passwords_does_not_match: "Las contraseñas no coinciden",
        },
    },

    error404: {
        labels: {
            heading:
                "Lo sentimos, parece que no podemos encontrar la página que estás buscando.",
            subheading:
                "Es posible que haya un error ortográfico en la URL introducida o que la página que estás buscando ya no exista.",
        },
    },

    home: {
        title: "Inicio",
    },
    cart: {
        title: "Carrito",
    }
};

export default translations;
