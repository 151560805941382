import React from "react";
import BgGlassmorphism from "modules/ui/components/BgGlassmorphism/BgGlassmorphism";
import PublicLayout from "modules/ui/components/PublicLayout";
import LegalNoticeText from "./LegalNoticeText";
import useCustomerContext from "modules/customers/hooks/useCustomerContext";

function LegalNotice() {
    const { customerTranslation } = useCustomerContext();

    return (
        <PublicLayout>
            <div className="nc-PageHome relative overflow-hidden">
                {/* GLASSMOPHIN */}
                <BgGlassmorphism />

                <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                    {/* MAIN SECTION */}
                    <div className="relative py-16">
                        <LegalNoticeText content={customerTranslation.legalNotice} />
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
}

export default LegalNotice;
