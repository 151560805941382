import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import BookingInfo from "../types/BookingInfo";

type Output = BookingInfo;

export default function useBookingInfoGet (
    bookingInternalId: any,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["booking-info", bookingInternalId],
        () => {
            return client.get<any>(`booking-info/` + bookingInternalId);
        },
        options
    );
}