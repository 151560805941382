import React, {FC, useEffect, useState} from "react";
import HotelRoom from "../../modules/hotels/types/HotelRoom";
import ButtonPrimary from "../../modules/ui/shared/Button/ButtonPrimary";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import useCheckAvailability from "../../modules/booking/hooks/useCheckAvailability";
import useBookingSearchContext from "../../modules/booking/hooks/useBookingSearchContext";
import BookingDates from "../../modules/booking/types/BookingDates";
import useCreatePrebooking from "../../modules/booking/hooks/useCreatePrebooking";
import NcInputNumber from "../../modules/ui/components/NcInputNumber/NcInputNumber";
import useCustomerContext from "../../modules/customers/hooks/useCustomerContext";
import useAddRoom from "../../modules/booking/hooks/useAddRoom";
import useCheckNumberOfHotelsOnBasket from "../../modules/booking/hooks/useCheckNumberOfHotelsOnBasket";
import HotelRoomFeedbackModal from "./HotelRoomFeedbackModal";
import {formatNumber} from "../../modules/common/utils/number";

export interface HotelRoomCardProps {
    hotelRoom: HotelRoom;
    eventCityId: any;
}

const HotelRoomCard: FC<HotelRoomCardProps> = ({
    hotelRoom,
    eventCityId
}) => {
    const t = useTranslate();
    const {
        dates: bookDates,
        rooms,
        prebookingId,
        setPrebookingId
    } = useBookingSearchContext();
    const { languageId } = useCustomerContext();
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(hotelRoom.total_price || 0);
    const [overbooking, setOverbooking] = useState(false);
    const [available, setAvailable] = useState(true);
    const [hotelAvailable, setHotelAvailable] = useState(true);
    const [roomAdded, setRoomAdded] = useState(false);
    const [unavailableDates, setUnavailableDates] = useState<BookingDates[]>([]);
    const [overbookingDates, setOverbookingDates] = useState<BookingDates[]>([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const checkAvailability = useCheckAvailability();
    const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
    const checkNumberOfHotels = useCheckNumberOfHotelsOnBasket();
    const createPrebooking = useCreatePrebooking(languageId);
    const { save:addRoom, error:addRoomError, isLoading:isAddingRoomLoading } = useAddRoom();

    useEffect(() => {
        let totalCapacity = hotelRoom.adults + hotelRoom.children;
        let requestQuantity = 0;
        let suggestedQuantity = 0;
        let roomPrice = hotelRoom.total_price || 0;

        rooms.forEach((room) => {
            let totalChildren = room.guestChildren || 0;
            let totalAdults = room.guestAdults || 0;
            requestQuantity = totalChildren + totalAdults;

            if (requestQuantity === totalCapacity) {
                suggestedQuantity = suggestedQuantity + 1;
            }
        });

        if(suggestedQuantity === 0) {
            suggestedQuantity = 1;
        }

        setQuantity(suggestedQuantity);
        setPrice(roomPrice * suggestedQuantity);

    }, [rooms]);

    const handleAddRoom = async () => {
        setModalIsOpen(false);
        let prebookingEventualId = prebookingId;

        if(!prebookingEventualId) {
            const newPrebookingId = await createPrebooking();
            setPrebookingId(newPrebookingId);
            prebookingEventualId = newPrebookingId;
        }

        addRoom({
            prebookingId: prebookingEventualId,
            eventHotelRoomId: hotelRoom.id,
            eventHotelId: hotelRoom.event_hotel_id,
            from: bookDates.startDate?.format("DD-MM-YYYY"),
            to: bookDates.endDate?.format("DD-MM-YYYY"),
            quantity: quantity
        }, {
            onSuccess: () => {
                setRoomAdded(true);
                setOverbooking(false);
                setAvailable(true);
                setUnavailableDates([]);
                setOverbookingDates([]);
                setModalIsOpen(true);
            },
            onError: () => {
                setRoomAdded(false);
                setOverbooking(false);
                setAvailable(true);
                setUnavailableDates([]);
                setOverbookingDates([]);
                setModalIsOpen(true);
            }
        });
    };

    const handleCheckAvailability = async () => {
        let basketAvailable = true;
        if (prebookingId) {
            setIsCheckingAvailability(true);
            const checkHotelsOnBasketAvailability = await checkNumberOfHotels(
                hotelRoom.id,
                prebookingId,
                hotelRoom.event_hotel_id
            );

            basketAvailable = checkHotelsOnBasketAvailability.available;
        }

        if(basketAvailable) {
            setIsCheckingAvailability(true);
            const data = await checkAvailability(
                hotelRoom.id,
                quantity,
                bookDates.startDate?.format("DD-MM-YYYY"),
                bookDates.endDate?.format("DD-MM-YYYY")
            );
            setIsCheckingAvailability(false);
            setAvailable(data.available);
            setOverbooking(data.overbooking);
            setUnavailableDates(data.unavailable_dates);
            setOverbookingDates(data.overbooking_dates);
            setRoomAdded(false);
            setModalIsOpen(true);

            if (data.available && !data.overbooking) {
                await handleAddRoom();
            }

        } else {
            setHotelAvailable(false);
            setRoomAdded(false);
            setModalIsOpen(true);
            setIsCheckingAvailability(false);
        }
    }

    const handleConfirmAddRoom = async () => {
        await handleAddRoom();
    }

    const handleChangeQuantity = (quantity: number) => {
        let roomPrice = hotelRoom.total_price || 0;
        let totalPrice = roomPrice * quantity;
        setPrice(totalPrice);
        setQuantity(quantity);
        setAvailable(true);
    }

    let modalKind: "roomAdded" |
        "roomNotAvailable" |
        "roomAvailableWithOverbooking" |
        "hotelNotAvailable" |
        "error" |
        null = null;

    if (roomAdded) {
        modalKind = 'roomAdded';
    } else {
        if (!available) {
            modalKind = 'roomNotAvailable';
        } else if(overbooking) {
            modalKind = 'roomAvailableWithOverbooking';
        } else if(!hotelAvailable) {
            modalKind = 'hotelNotAvailable';
        } else if(addRoomError) {
            modalKind = 'error';
        }
    }

    return (
        <div className="listingSectionSidebar__wrap shadow-xl mb-5">
            {/* PRICE */}
            <div className="flex justify-between">
                <span className="text-1xl font-semibold">
                    <i className="text-2xl las la-bed la-fw" style={{marginRight : "5px"}}></i> {hotelRoom.type}
                </span>
            </div>

            {/* SUM */}
            <div className="flex flex-col space-y-4">
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>{t("hotelDetail.from")}</span>
                    <span>{bookDates.startDate?.format("DD/MM/YYYY")}</span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>{t("hotelDetail.to")}</span>
                    <span>{bookDates.endDate?.format("DD/MM/YYYY")}</span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>{t("hotelDetail.capacity")}</span>
                    <span>
                        {[...Array(hotelRoom.adults)].map((value, index) =>
                            <i className="las la-user la-2x" key={index + hotelRoom.id}></i>
                        )}
                        {[...Array(hotelRoom.children)].map((value, index) =>
                            <i className="text-2xl las la-user" key={index + hotelRoom.id}></i>
                        )}
                    </span>
                </div>

                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>Quantity:</span>
                    <NcInputNumber
                        className=""
                        defaultValue={quantity}
                        onChange={(value) => handleChangeQuantity(value)}
                        max={10}
                        min={1}
                    />
                </div>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                    <span>{t("hotelDetail.total")}</span>
                    <div>{formatNumber(price)} <span dangerouslySetInnerHTML={{ __html:hotelRoom.currency}} /></div>
                </div>
            </div>

            {/* SUBMIT */}
            <ButtonPrimary href={`/event-city/${eventCityId}`} className="uppercase">
                <i className="text-2xl las la-calendar la-fw"></i> {t("hotelDetail.modifyDates")}
            </ButtonPrimary>

            <ButtonPrimary
                onClick={handleCheckAvailability}
                disabled={!available || isCheckingAvailability || isAddingRoomLoading}
                className="uppercase"
                loading={isCheckingAvailability || isAddingRoomLoading}
            >
                <i className="text-2xl las la-shopping-cart la-fw"></i> {t("hotelDetail.addRoom")}
            </ButtonPrimary>

            {modalKind && (
                <HotelRoomFeedbackModal
                    kind={modalKind}
                    isOpen={modalIsOpen}
                    onClose={() => setModalIsOpen(false)}
                    onConfirmAddRoom={handleConfirmAddRoom}
                    overbookingDates={overbookingDates}
                    unavailableDates={unavailableDates}
                />
            )}
        </div>
    );
};

export default HotelRoomCard;
