import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import Event from "../../../modules/events/types/Event";

type Output = Event[];

export default function useEventsByCustomerGet (
    customerId?: number,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["customer-events", customerId],
        () => {
            return client.get<any>(`events-by-customer`, {
                "customerId": customerId
            });
        },
        options
    );
}