import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import BasketItem from "../types/BasketItem";

type Output = BasketItem[];

export default function useGetBasketItems (
    prebookingId: any,
    languageId: number,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["basket-items", prebookingId, languageId],
        () => {
            return client.get<any>(`prebooking-items-get`, {
                prebookingId,
                languageId
            });
        },
        options
    );
}