import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import HotelExtraInfo from "../types/HotelExtraInfo";

type Output = HotelExtraInfo[];

export default function useHotelExtraInfoGet (
    hotelId: any,
    languageId: number,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["hotel-extra-info-by-hotel", hotelId, languageId],
        () => {
            return client.get<any>(`hotel-extra-info/hotel/`+hotelId, {
                "languageId": languageId
            });
        },
        options
    );
}