import React from "react";
import { useQueryClient } from "react-query";

export default function useCacheRemove(): (key: string | unknown[]) => void {
    const queryClient = useQueryClient();

    return React.useCallback(key => queryClient.invalidateQueries(key), [
        queryClient,
    ]);
}
