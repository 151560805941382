import React from 'react';
import { createPortal } from "react-dom";
import useCustomerContext from "../hooks/useCustomerContext";

export const getRGBColor = (hex: string) => {
    let color = hex.replace(/#/g, "")
    // rgb values
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    return `${r}, ${g}, ${b};`
}

const getColorCssVars = (color: any, type: string) =>
    Object.entries(color).map(
        ([key, value]) =>
            `--c-${type}-${key}: ${getRGBColor(value as string)} !important;`).join("");

const CustomerTheme = () => {
    const { theme } = useCustomerContext();

    return theme && createPortal((
        <style>
            {`
            :root {
                ${getColorCssVars(theme.primaryColor, 'primary')}
                ${getColorCssVars(theme.secondaryColor, 'secondary')}
            }
            `}
        </style>
    ), document.head);
};

export default CustomerTheme;
