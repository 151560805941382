import React, { FC } from "react";
import Logo from "modules/ui/shared/Logo/Logo";
import LangDropdown from "./LangDropdown";
import { Link } from "react-router-dom";
import useTranslate from "../../../core/languages/hooks/useTranslate";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import useCustomerContext from "../../../customers/hooks/useCustomerContext";
import useBookingSearchContext from "../../../booking/hooks/useBookingSearchContext";
import BasketBadge from "./BasketBadge";

const MainNav: FC = () => {
  const { customer, theme } = useCustomerContext();
  const { prebookingId } = useBookingSearchContext();

  const t = useTranslate();

  const bgClass = theme ? ' bg-secondary-900' : '';

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10${bgClass}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo
              img={process.env.REACT_APP_API_CUSTOMER_LOGO_IMAGE_STORAGE + customer.logo}
              imgLight={process.env.REACT_APP_API_CUSTOMER_LOGO_IMAGE_STORAGE + customer.logo}
          />
        </div>

        <div className="md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="items-center flex space-x-1">
            <Link
                to="/checkout"
                className="
                inline-flex text-opacity-90 bg-neutral-100
                group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <ShoppingCartIcon className="w-[18px] h-[18px] opacity-80" />
              <span className="ml-2 select-none">
                {t("mainNav.shoppingBasket")}
                {prebookingId && (
                    <BasketBadge basketId={prebookingId} />
                )}
              </span>
            </Link>
            <LangDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav;
