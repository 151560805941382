import React, { FC, useEffect, useState } from "react";
import Heading from "modules/ui/shared/Heading/Heading";
import { ReactNode } from "react";

export interface MainCitiesHeaderFilterProps {
    heading: ReactNode;
    subHeading?: ReactNode;
}

const MainCitiesHeader: FC<MainCitiesHeaderFilterProps> = ({
    subHeading,
    heading = "Loren Ipsum",
}) => {

    return (
        <div className="flex flex-col mb-4 relative">
            <Heading desc={subHeading}>{heading}</Heading>
            <div className="flex items-center justify-between">
                <span className="hidden sm:block flex-shrink-0">
        </span>
            </div>
        </div>
    );
};

export default MainCitiesHeader;
