import React from "react";

interface LegalNoticeTextProps {
    content: string;
}

const LegalNoticeText = ({
    content,
}: LegalNoticeTextProps) => {
    return <span dangerouslySetInnerHTML={{__html: content}} />;
}

export default LegalNoticeText;
