import React from "react";
import useCustomerContext from "modules/customers/hooks/useCustomerContext";

const CustomerDescription = () => {
    const { customerTranslation } = useCustomerContext();
    return (
        <div className="mt-5">
            <span dangerouslySetInnerHTML={{__html: customerTranslation.info}}/>
        </div>
    );
}

export default CustomerDescription;
