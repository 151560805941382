import React, {FC, Fragment, useState} from "react";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import BasketItem from "../../modules/booking/types/BasketItem";
import NcImage from "../../modules/ui/shared/NcImage/NcImage";
import {StarIcon} from "@heroicons/react/24/solid";
import useGetBookPriceDetail from "../../modules/booking/hooks/useGetBookPriceDetail";
import {Dialog, Transition} from "@headlessui/react";
import ButtonClose from "../../modules/ui/shared/ButtonClose/ButtonClose";
import useDeletePrebookingRoom from "../../modules/booking/hooks/useDeletePrebookingRoom";
import ButtonSecondary from "../../modules/ui/shared/Button/ButtonSecondary";
import Loader from "../../modules/ui/shared/Loader/Loader";

export interface CheckoutItemProps {
    item: BasketItem;
}

const CheckoutItem: FC<CheckoutItemProps> = ({
    item,
}) => {
    const t = useTranslate();
    const [isOpenModalPriceDetail, setIsOpenModalPriceDetail] = useState(false);

    const bookItemPriceDetailQuery = useGetBookPriceDetail(
        item.room_hotel_event_id,
        item.book_from,
        item.book_to
    , {
        execute: isOpenModalPriceDetail
    });
    const {data: bookPriceDetail, isLoading: isLoadingBookPriceDetail} = bookItemPriceDetailQuery;
    const { save: deleteItem } = useDeletePrebookingRoom();

    function closeModalPriceDetail() {
        setIsOpenModalPriceDetail(false);
    }

    const handleDeleteItem = () => {
        deleteItem({
            itemId: item.item_id
        });
    }

    const renderModalPriceDetail = () => {
        return (
            <Transition appear show={isOpenModalPriceDetail} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModalPriceDetail}>
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
                        </Transition.Child>
                        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block py-8 h-screen w-full max-w-4xl">
                                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                                    <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                                            {t("checkout.book_price_detail")}
                                        </h3>
                                        <span className="absolute left-3 top-3">
                                            <ButtonClose onClick={closeModalPriceDetail} />
                                        </span>
                                    </div>
                                    <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                                        {bookPriceDetail && bookPriceDetail.map((bookDetail, index) => (
                                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8" key={index}>
                                                <span>{t("checkout.night_of")} {bookDetail.date}</span>
                                                <span>{bookDetail.price} <span dangerouslySetInnerHTML={{ __html:item.currency_symbol}} /></span>
                                            </div>

                                        ))}

                                        {isLoadingBookPriceDetail && <Loader centerHorizontally />}

                                        <div className="flex flex-col space-y-4">
                                            <div className="flex justify-between font-semibold">
                                                <span>{t("checkout.total")}</span>
                                                <div>{item.price_with_taxes} <span dangerouslySetInnerHTML={{ __html:item.currency_symbol}} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row sm:items-center">
                <div className="flex-shrink-0 w-full sm:w-40">
                    <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                        <NcImage src={(process.env.REACT_APP_API_HOTEL_STORAGE as string) + item.hotel_id + "/" + item.hotel_image} />
                    </div>
                </div>
                <div className="sm:px-5 space-y-1 w-[50%]">
                    <div>
                        <span className="text-base font-medium mt-1 block">
                            <div className="flex items-end">
                                {[...Array(item.hotel_stars)].map((value, index) => <StarIcon className="w-[18px] h-[18px] text-orange-500" key={index} />)}
                            </div>
                            {item.hotel_name}
                        </span>
                    </div>
                    <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                            {item.room_name}
                        </span>
                    <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                            {t("checkout.from")}: {item.book_from_to_front}
                    </span>
                    <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                            {t("checkout.to")}: {item.book_to_to_front}
                    </span>
                    <span className="block  text-sm text-neutral-500 dark:text-neutral-400 flex items-center !mt-2">
                        <span>{t("hotelDetail.capacity")}</span>
                        <span className="ml-2">
                            {[...Array(item.adults)].map((value, index) =>
                                <i className="las la-user la-2x" key={index + item.item_id}></i>
                            )}
                            {[...Array(item.childrens)].map((value, index) =>
                                <i className="text-2xl las la-user" key={index + item.item_id}></i>
                            )}
                        </span>
                    </span>
                </div>
                <div className="py-5 sm:px-5 space-y-3 w-4/6">
                    <div className="flex flex-col space-y-4">
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            <span>{t("checkout.total_nights")}</span>
                            <span>{item.total_nights} {t("checkout.nights")}</span>
                        </div>
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            <span>{t("checkout.total_without_taxes")}</span>
                            <div>{item.price_without_taxes} <span dangerouslySetInnerHTML={{ __html:item.currency_symbol}} /></div>
                        </div>
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            <span>{t("checkout.total_taxes")}</span>
                            <div>{item.taxes} <span dangerouslySetInnerHTML={{ __html:item.currency_symbol}} /></div>
                        </div>

                        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                        <div className="flex justify-between font-semibold">
                            <div className="flex flex-row">
                                <span>{t("checkout.total_to_pay")}</span>
                                <button
                                    onClick={() => setIsOpenModalPriceDetail(true)}
                                    className={`items-center p-2 -m-3 text-left pl-5`}
                                ><i className={"text-3xl las la-question-circle"}></i>
                                </button>
                            </div>
                            <div>{item.price_with_taxes} <span dangerouslySetInnerHTML={{ __html:item.currency_symbol}} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ButtonSecondary onClick={handleDeleteItem} className="float-right"><i className={"text-3xl las la-trash-alt"}></i> {t("checkout.delete")}</ButtonSecondary>
            </div>
            {renderModalPriceDetail()}
        </>
    );
}

export default CheckoutItem;
