import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import BasketItemTotal from "../types/BasketItemTotal";

export default function useCountBasketItems (
    prebookingId: any,
    options?: CacheQueryOpts
): CacheQueryOutput<BasketItemTotal> {
    const client = useAPIClient();
    return useCacheQuery<BasketItemTotal>(
        ["basket-count"],
        () => {
            return client.get<any>(`prebooking-items-count`, {
                prebookingId
            });
        },
        options
    );
}