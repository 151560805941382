const translations = {
    mainNav: {
        language: "Language",
        shoppingBasket: "Basket",
    },
    footer: {
        legalNotice: "Legal notice",
        privacyPolicy: "Privacy policy",
        cookiesPolicy: "Cookies policy",
        complaintChannel: "Complaint Channel"
    },
    bookingForm: {
        event: "Cобытие",
        checkIn: "Check in",
        checkOut: "Check out",
        guest: "Guest",
        guests: "Guests",
        addDate: "Add date",
        addGuest: "Add",
        numberOfRooms: "Rooms",
        adults: "Adults",
        children: "Children",
        roomNumber: "Room {{number}}"
    },
    cityEventMainCard: {
        hotels: "Hotels",
        transferService: "Transfer service",
        from: "From",
        to: "until",
    },
    hotelMapCard: {
        minNightsRequired: "Este hotel requiere un mínimo de {{noches}} noches",
    },
    hotelDetail: {
        amenities: "Amenities",
        amenitiesDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        viewMoreAmenities: "view more amenities",
        informationAndExtras: "Information and Extras",
        informationAndExtrasDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        location: "Location",
        cancellationPolicy: "Cancellation Policy",
        modifyDates: "Modify dates",
        addRoom: "Add room",
        from: "From:",
        to: "To:",
        capacity: "Capacity:",
        total: "Total",
        roomAdded: "Added room",
        roomNotAvailable: "Room not available",
        roomNotAvailableAt: "There is no availability for these nights: {{dates}}",
        roomAvailableWithOverbooking: "Room with days pending confirmation",
        roomAvailableWithOverbookingAt: "When making the reservation, the hotel staff will confirm if there is availability for these nights: {{dates}}",
        roomAvailableWithOverbookingConfirm: "If you agree, click confirm to book.",
        hotelNotAvailable: "No puede agregar habitaciones de hoteles diferentes",
        addRoomErrorTitle: "An error ocurred.",
        addRoomError: "An error occurred and the room could not be added to the basket",
        goToCheckout: "Go to checkout",
        details: "Details",
    },
    common: {
        actions: {
            add: "Añadir",
            accept: "Aceptar",
            cancel: "Cancelar",
            edit: "Editar",
            delete: "Eliminar",
            submit: "Enviar",
            save: "Guardar",
            upload_file: "Subir archivo",
            search: "Buscar",
            show_more_filters: "Mostrar más filtros",
            hide_more_filters: "Ocultar más filtros",
            clear: "Limpiar",
            back: "Volver",
            goHome: "Ir a inicio",
            copy: "Copiar",
            confirm: "Confirm",
        },
        labels: {
            required_field: "Campo obligatorio",
            filters: "Filtros",
            none: "Ninguno",
            all: "Todos",
            creation_date: "Fecha de creación",
            error: "Error",
            administration: "Administración",
            actions: "Acciones",
            selected_items: "{{count}} elemento/s seleccionado/s",
            per_page: "Por página",
            sort_by: "Ordenar por",
            total: "Total",
            language: "Idioma",
        },
        feedback: {
            saved_data: "Datos guardados con éxito",
            no_data: "No hay datos",
            form_not_valid: "Hay errores en el formulario",
            field_required: "El campo es obligatorio",
            field_min: "El valor del campo no puede ser inferior a {{min}}",
            field_max: "El valor del campo no puede ser superior a {{max}}",
            field_min_length:
                "La longitud del campo no puede ser menor a {{length}}",
            field_max_length:
                "La longitud del campo no puede ser mayor a  {{length}}",
            field_valid_email: "{{value}} no es un correo electrónico válido",
            field_valid_iban: "{{value}} no es un IBAN correcto",
            unexpected_error: "Ha ocurrido un error inesperado",
        },
    },

    auth: {
        actions: {
            sign_in: "Iniciar sesión",
            logout: "Cerrar sesión",
            reset_password: "Restablecer contraseña",
        },
        fields: {
            email: "Correo electrónico",
            password: "Contraseña",
            new_password: "Nueva contraseña",
            confirm_new_password: "Confirmar nueva contraseña",
        },
        labels: {
            forgot_password: "¿Has olvidado la contraseña?",
            forgot_password_subheading:
                "Introduce tu correo electrónico para restablecer tu contraseña",
        },
        feedback: {
            unauthorized: "La sesión no es válida",
            passwords_does_not_match: "Las contraseñas no coinciden",
        },
    },

    error404: {
        labels: {
            heading:
                "Lo sentimos, parece que no podemos encontrar la página que estás buscando.",
            subheading:
                "Es posible que haya un error ortográfico en la URL introducida o que la página que estás buscando ya no exista.",
        },
    },

    home: {
        title: "Inicio",
    },
    cart: {
        title: "Carrito",
    },
    checkout: {
        title: "Booking details",
        continueShopping: "Continue shopping",
        noItemsInTheCart: "There are no items in the cart",
        total: "Total",
        total_without_taxes: "Total without taxes",
        total_taxes: "Taxes",
        discount: "Discount",
        total_to_pay: "Total to pay",
        confirm_booking: "Confirm booking",
        clear_basket: "Clear basket",
        total_nights: "Nights",
        nights: "nights",
        night_of: "Night of",
        from: "From",
        to: "To",
        delete: "Delete",
        book_price_detail: "Book price detail",
    },
    booking: {
        title: "Confirm and payment",
        personalInformation: "Personal information",
        name: "Name",
        surname: "Surname",
        email: "Email",
        telephone: "Telephone",
        invalidTelephone: "Please enter a valid telephone number",
        roomsWithOverbookingDisclaimer: "Once the reservation is done we will contact you as soon as possible because this booking has to be approved:",
        roomsToBePaidAtTheHotelDisclaimer: "This reservation contains rooms to be paid at the hotel and the credit card details are required to guarantee the room with the hotel.",
        paymentRequiredDisclaimer: "Someone from the Love Events team will contact you shortly to make the payment.",
        creditCardInformation: "Credit card information",
        creditCardHolder: "Credit card holder",
        creditCardNumber: "Credit card number",
        invalidCreditCardNumber: "Please enter a valid credit card number",
        expiration: "Expiration",
        invalidCreditCardExpiration: "The expiration date of the credit card must be after the date of the reservation",
        roomDetails: "Room details",
        room: "Room",
        guests: "Guests",
        guestN: "Guest {{number}}",
        checkInTime: "Check in time",
        iDontKnow: "I don't know",
        comments: "Comments",
        acceptTerms: "I have read and I accept ",
        termsAndConditions: "Terms & Conditions",
        submit: "Finalise the booking",
        formContainsErrors: "The form contains errors. Please check them.",
        errorWhenMakingTheBookingHeading: "Error when making the reservation",
        errorWhenMakingTheBookingBody: "An error has occurred while making the reservation. Please try again. If the problem persists, contact us.",
        prebookingHasExpiredError: "The prebooking it has expired after 15 minutes from starting the booking without finishing it. Please, start again the booking process",
        bookingDetails: "Booking details",
    },
    bookOk: {
        yourBooking: "Your booking",
        bookingConfirmed: "Your booking has been confirmed",
        roomsWithOverbookingDisclaimer: "Once the reservation is done we will contact you as soon as possible because this booking has to be approved",
        rememberPay: "Remember, when you arrive to the hotel, you will need to pay:  ",
        confirmationNumber: "Confirmation number",
        nameAndSurnames: "Name and surnames",
        email: "Contact email",
        telephone: "Contact telephone",
        hotelDetails: "Hotel details",
        event: "Event",
        city: "City",
        hotel: "Hotel",
        address: "Address",
        payMethod: "Pay method",
        payOnHotel: "Pay on hotel",
        roomDetails: "Room Details",
        roomType: "Room type",
        occupancy: "Occupancy",
        arrivalHour: "Arrival hour",
        guest: "Guest",
        total: "Total"
    }
};

export default translations;
