import React from "react";
import MyRouter from "pages/index";
import CacheClientProvider from "modules/core/services/query/components/CacheClientProvider";
import CustomerContextProvider from "./modules/customers/components/CustomerContextProvider";
import BookingSearchProvider from "./modules/booking/components/BookingSearchProvider";
import CustomerTheme from "./modules/customers/components/CustomerTheme";

function App() {
  return (
      <CacheClientProvider>
          <CustomerContextProvider>
              <CustomerTheme />
              <BookingSearchProvider>
                  <MyRouter />
              </BookingSearchProvider>
          </CustomerContextProvider>
      </CacheClientProvider>
  );
}

export default App;
