import React, {FC, Fragment, useState} from "react";
import HotelDetail from "../../../modules/hotels/types/HotelDetail";
import {Transition} from "@headlessui/react";
import HotelMapCard from "./HotelMapCard";
import {formatNumber} from "../../../modules/common/utils/number";

export interface HotelsMapPinsProps {
    hotelDetail: HotelDetail,
    isSelected?: boolean;
    lat: number;
    lng: number;
    cityEventId: number;
}

const HotelsMapPins: FC<HotelsMapPinsProps> = ({
    hotelDetail,
    isSelected,
    cityEventId
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={`nc-AnyReactComponent relative`}
            data-nc-id="AnyReactComponent"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
        >
      <div
          className={`flex px-2 py-1 rounded-lg bg-primary-800 text-primary-50 dark:bg-neutral-900 text-sm font-semibold items-center justify-center min-w-max shadow-lg hover:bg-primary-800 dark:hover:bg-white dark:hover:text-neutral-900 transition-colors ${
              isSelected
                  ? "bg-neutral-900 text-white dark:bg-white dark:text-neutral-900"
                  : ""
          }`}
      >
        {formatNumber(hotelDetail.suggestedRoom.total_price)} {' '}
        <span dangerouslySetInnerHTML={{ __html:hotelDetail.suggestedRoom?.currency}} />
      </div>
            <Transition
                show={isOpen}
                as={Fragment}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="absolute z-50 bottom-full pb-3 -left-12 w-[260px] aspect-w-1">
                    <HotelMapCard cityEventId={cityEventId} size="small" hotelDetail={hotelDetail} className="shadow-2xl" />
                </div>
            </Transition>
        </div>
    );
};

export default HotelsMapPins;
