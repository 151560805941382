import {createContext, FC, ReactNode, useCallback, useEffect, useState} from 'react';
import CityName from "../../cities/types/CityName";
import { DateRange } from "./HeroSearchForm/BookingForm";
import moment from "moment";
import {GuestsObject} from "../types";

interface BookingSearchContextData {
    city: CityName | null;
    setCity: (city: CityName | null) => void;
    dates: DateRange,
    setDates: (dates: DateRange) => void;
    rooms: GuestsObject[];
    setRooms: (rooms: GuestsObject[]) => void;
    cities: CityName[];
    setCities: (cities: CityName[]) => void;
    prebookingId: string | null;
    setPrebookingId: (prebooking: string | null) => void;
    prebookingHasExpired: boolean;
    setPrebookingHasExpired: (value: boolean) => void;
}

export const BookingSearchContext = createContext<BookingSearchContextData>({} as any);

const BookingSearchProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [city, setCity] = useState<BookingSearchContextData['city']>(() => {
        const storedValue = localStorage.getItem('bookingSearchContext.city');
        return storedValue ? JSON.parse(storedValue) : null;
    });

    const [dates, setDates] = useState<BookingSearchContextData['dates']>(() => {
        const storedValue = localStorage.getItem('bookingSearchContext.dates');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            parsedValue.startDate = parsedValue.startDate ? moment(parsedValue.startDate) : null;
            parsedValue.endDate = parsedValue.endDate ? moment(parsedValue.endDate) : null;
            return parsedValue;
        }
        return { startDate: null, endDate: null }
    });

    const [rooms, setRooms] = useState<BookingSearchContextData['rooms']>(() => {
        const storedValue = localStorage.getItem('bookingSearchContext.rooms');
        return storedValue ? JSON.parse(storedValue) : [{ guestAdults: 1, guestChildren: 0 }];
    });

    const [cities, setCities] = useState<CityName[]>([]);

    const [prebookingExpiresAt, setPrebookingExpiresAt] = useState<string | null>(() =>
        localStorage.getItem('bookingSearchContext.prebookingExpiresAt') || null);

    const [prebookingId, _setPrebookingId] = useState<BookingSearchContextData['prebookingId']>(() =>
        localStorage.getItem('bookingSearchContext.prebookingId') || null);

    const [prebookingHasExpired, setPrebookingHasExpired] = useState(false);

    useEffect(() => {
        if (city) {
            localStorage.setItem('bookingSearchContext.city', JSON.stringify(city));
        } else {
            localStorage.removeItem('bookingSearchContext.city');
        }
    }, [city]);

    useEffect(() => {
        localStorage.setItem('bookingSearchContext.dates', JSON.stringify(dates));
    }, [dates]);

    useEffect(() => {
        localStorage.setItem('bookingSearchContext.rooms', JSON.stringify(rooms));
    }, [rooms])

    useEffect(() => {
        let interval: any = null;

        const prebookingExpiresIn = prebookingExpiresAt
            ? moment(prebookingExpiresAt).diff(moment())
            : null;

        if (prebookingExpiresIn !== null && prebookingExpiresIn > 0) {
            interval = setInterval(() => {
                setPrebookingHasExpired(true);
                _setPrebookingId(null);
                localStorage.removeItem('bookingSearchContext.prebookingId');
            }, prebookingExpiresIn);
        }

        if (prebookingExpiresIn !== null && prebookingExpiresIn < 0) {
            setPrebookingHasExpired(true);
            _setPrebookingId(null);
            localStorage.removeItem('bookingSearchContext.prebookingId');
        }

        return interval ? (() => clearInterval(interval)) : undefined;
    }, [prebookingExpiresAt]);

    const setPrebookingId = useCallback((newPrebookingId: string | null) => {
        if (prebookingId === newPrebookingId) return;

        _setPrebookingId(newPrebookingId);
        setPrebookingHasExpired(false);

        if (typeof newPrebookingId === "string") {
            localStorage.setItem('bookingSearchContext.prebookingId', newPrebookingId);
            const expiresAt = moment()
                .add(
                    parseInt(process.env.REACT_APP_PREBOOKING_EXPIRES_IN as string),
                    'minutes'
                )
                .toISOString();

            setPrebookingExpiresAt(expiresAt);
            localStorage.setItem('bookingSearchContext.prebookingExpiresAt', expiresAt);
        } else if (newPrebookingId === null) {
            localStorage.removeItem('bookingSearchContext.prebookingId');
            setPrebookingExpiresAt(null);
            localStorage.removeItem('bookingSearchContext.prebookingExpiresAt');
        }
    }, [prebookingId]);

    return (
        <BookingSearchContext.Provider value={{
            city,
            setCity,
            dates,
            setDates,
            rooms,
            setRooms,
            cities,
            setCities,
            prebookingId,
            setPrebookingId,
            prebookingHasExpired,
            setPrebookingHasExpired
        }}>
            {children}
        </BookingSearchContext.Provider>
    );
};

export default BookingSearchProvider;
