import React, {FC} from "react";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import HotelExtraInfo from "../../modules/hotels/types/HotelExtraInfo";

export interface HotelExtraInfoCardProps {
    hotelExtraInfo: HotelExtraInfo[];
}

const HotelExtraInfoCard: FC<HotelExtraInfoCardProps> = ({
    hotelExtraInfo,
}) => {
    const t = useTranslate();
    return (
        <div className="listingSection__wrap !mt-5">
            <div>
                <h2 className="text-2xl font-semibold">
                    {t("hotelDetail.details")}
                </h2>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-1 gap-y-6 gap-x-10 text-sm text-neutral-900 dark:text-neutral-300">

                {hotelExtraInfo.map((item, index) => (

                    <div key={index}>
                        <div className="mb-1 text-sm text-neutral-900 dark:text-neutral-300">
                            {item.title}
                        </div>
                        <span className="text-sm text-neutral-500 dark:text-neutral-400"  style={{display : "block"}}>{item.description}</span>
                    </div>

                ))}
            </div>
        </div>
    );
};

export default HotelExtraInfoCard;
