import React from "react";

interface PrivacyPolicyTextProps {
    content: string;
}

const PrivacyPolicyText = ({ content }: PrivacyPolicyTextProps) => {
    return <span dangerouslySetInnerHTML={{__html: content}} />;
}

export default PrivacyPolicyText;
