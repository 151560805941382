import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import {
    availableLanguages,
    defaultLanguage,
    languageResources,
} from "./index";
import moment from "moment";

i18next.on("languageChanged", (lang: any) => moment.locale(lang));

export default i18next
    .use(I18nextBrowserLanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next
    .init({
        resources: languageResources,
        fallbackLng: [defaultLanguage], //Fallback language
        detection: {
            checkWhitelist: true, // options for language detection
        },
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });
