import React, { useEffect } from 'react';
import MainNav from "../Header/MainNav";
import {Helmet} from "react-helmet";
import Footer from "../../shared/Footer/Footer";
import useCustomerContext from "../../../customers/hooks/useCustomerContext";
import useBookingSearchContext from "../../../booking/hooks/useBookingSearchContext";
import {Alert} from "../../shared/Alert/Alert";
import useTranslate from "../../../core/languages/hooks/useTranslate";

interface PublicLayoutProps {
    children: React.ReactNode;
}

const PublicLayout = ({
    children
}: PublicLayoutProps) => {
    const { customer, customerTranslation } = useCustomerContext();
    const { prebookingHasExpired, setPrebookingHasExpired } = useBookingSearchContext();

    const t = useTranslate();

    useEffect(() => {
        return () => setPrebookingHasExpired(false);
    }, []);

    return (
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            <Helmet>
                <title>{customer.name}</title>
            </Helmet>

            <MainNav />

            {prebookingHasExpired && (
                <Alert type="warning" className="rounded-none" onClose={() => setPrebookingHasExpired(false)}>
                    <div className="text-center w-full">{t("booking.prebookingHasExpiredError")}</div>
                </Alert>
            )}

            {children}

            <Footer content={customerTranslation.footer} />
        </div>
    );
};

export default PublicLayout;
