import React, {FC} from "react";
import EventHotelTranslation from "../../modules/eventHotels/types/EventHotelTranslation";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";

export interface HotelCancellationPolicyCardProps {
    eventHotelTranslation: EventHotelTranslation;
}

const HotelCancellationPolicyCard: FC<HotelCancellationPolicyCardProps> = ({
    eventHotelTranslation,
}) => {
    const t = useTranslate();
    return (
        <div className="listingSection__wrap !mt-5">
            <h2 className="text-2xl font-semibold">{t("hotelDetail.cancellationPolicy")}</h2>
            <div className="text-neutral-6000 dark:text-neutral-300">
                <span dangerouslySetInnerHTML={{__html: eventHotelTranslation.cancellation_policy}}/>
            </div>
        </div>
    );
};

export default HotelCancellationPolicyCard;
