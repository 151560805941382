import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import HotelDetail from "../../hotels/types/HotelDetail";

type Output = HotelDetail[];

export default function useEventCityHotelsDetailsGet(
    eventCityId: any,
    languageId: number,
    bookFrom: any,
    bookUntil: any,
    minimumOccupancy: number,
options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        [
            "event-city-hotels",
            eventCityId,
            {
                languageId,
                bookFrom,
                bookUntil,
                minimumOccupancy
            }
        ],
        () => {
            return client.get<any>(`event-city-hotels/` + eventCityId, {
                languageId,
                bookFrom,
                bookUntil,
                minimumOccupancy
            });
        },
        options
    );
}