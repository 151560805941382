import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useDataSave, {DataSaveType} from "../../core/services/query/hooks/useDataSave";
import useCacheRemove from "../../core/services/query/hooks/useCacheRemove";

export interface AddRoomInput {
    prebookingId: string | null;
    eventHotelRoomId: number;
    eventHotelId: number;
    from?: string;
    to?: string;
    quantity: number;
}

export default function useAddRoom (): DataSaveType<AddRoomInput> {
    const client = useAPIClient();
    const removeCache = useCacheRemove();

    return useDataSave<AddRoomInput>(
        input => client.post("add-prebooking-rooms", input),
        {
            onSuccess: () => removeCache("basket-count"),
        }
    );
}