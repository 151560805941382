import React, { FC } from "react";
import CityCard from "./../Cities/CityCard";
import useCityTranslationByCityAndLanguageGet
    from "../../../modules/cities/hooks/useCityTranslationByCityAndLanguageGet";
import useCustomerContext from "../../../modules/customers/hooks/useCustomerContext";

export interface AsideEventCityCardProps {
    cityId: number;
    cityBanner: string;
    eventCityId: number;
}

const AsideEventCityCard: FC<AsideEventCityCardProps> = ({
    cityId,
    cityBanner,
    eventCityId,
}) => {
    const { languageId } = useCustomerContext();

    const cityTranslationQuery = useCityTranslationByCityAndLanguageGet(cityId, languageId);
    const { data: cityTranslation } = cityTranslationQuery;
    return (
        <CityCard
            key={cityId}
            cityImage={process.env.REACT_APP_API_CITY_BANNERS_STORAGE + cityBanner}
            cityName={cityTranslation?.name}
            eventCityId={eventCityId}
        />
    );
};

export default AsideEventCityCard;
