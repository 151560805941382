import React, {useCallback, useEffect, useState} from "react";
import GuestsInput from "./GuestsInput";
import {FocusedInputShape} from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import moment from "moment";
import {FC} from "react";
import CityName from "../../../cities/types/CityName";
import useCustomerContext from "../../../customers/hooks/useCustomerContext";
import useCityListForActiveEventsGet from "../../../eventCities/hooks/useCityListForActiveEventsGet";
import CityInput from "./CityInput";
import useBookingSearchContext from "../../hooks/useBookingSearchContext";
import {useNavigate} from "react-router-dom";
import ButtonSearch from "./ButtonSearch";
import { GuestsObject } from "../../types";

export interface DateRange {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
}

const BookingForm: FC = () => {
    const navigate = useNavigate();

    const {
        city: contextCity,
        setCity: setContextCity,
        dates: contextDates,
        setDates: setContextDates,
        rooms: contextRooms,
        setRooms: setContextRooms,
        setCities: setContextCities
    } = useBookingSearchContext();

    const [inputCity, setInputCity] = useState<CityName | null>(contextCity);
    const [inputDates, setInputDates] = useState<DateRange>(contextDates);
    const [inputRooms, setInputRooms] = useState<GuestsObject[]>(contextRooms);
    const [open, setOpen] = useState(false);

    const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
        null
    );

    const {languageId, customer} = useCustomerContext();

    const {data: cities} = useCityListForActiveEventsGet(customer.id, languageId);

    useEffect(() => {
        if (cities) setContextCities(cities);
    }, [cities, setContextCities]);

    useEffect(() => {
        setInputCity(contextCity);
    }, [contextCity]);

    useEffect(() => {
        setInputDates(contextDates);
    }, [contextDates]);

    useEffect(() => {
        setInputRooms(contextRooms);
    }, [contextRooms])

    const handleSelectCity = useCallback((city: CityName) => {
        setInputCity(city)
        setDateFocused("startDate");
        setInputDates({
            startDate: moment(city.event_start_date),
            endDate: moment(city.event_end_date),
        })
    }, []);

    const handleSubmit = useCallback((ev: any) => {
        ev.preventDefault();

        setContextCity(inputCity);
        setContextDates(inputDates);
        setContextRooms(inputRooms);

        if (!inputCity) return;
        setOpen(false);
        navigate(`/event-city/${inputCity.id}`);
    }, [inputCity, setContextCity, inputDates, setContextDates, inputRooms, setContextRooms]);

    const renderForm = () => {
        return (
            <>
                <div className={`${(open ? 'hidden ' : 'flex ')}px-2 lg:hidden`}>
                    <ButtonSearch onClick={() => setOpen(true)}/>
                </div>
                <form
                    className={`${open ? 'flex ' : 'hidden '}w-full relative mt-8 lg:flex flex-col lg:flex-row lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 bg-opacity-95`}
                    onSubmit={handleSubmit}
                >
                    <div className="flex justify-end lg:hidden px-2">
                        <button type="button" onClick={() => setOpen(false)}>
                            <i className="las la-times"></i>
                        </button>
                    </div>
                    {cities ?
                        <CityInput
                            cityEvents={cities}
                            defaultValue={inputCity}
                            onInputDone={handleSelectCity}
                        />
                        : null}
                    <StayDatesRangeInput
                        defaultValue={inputDates}
                        defaultFocus={dateFocused}
                        onChange={(data) => setInputDates(data)}
                        className="flex-[2]"
                        enableFromDay={inputCity?.book_start_date}
                        enableUntilDay={inputCity?.book_end_date}
                    />
                    <GuestsInput
                        defaultValue={inputRooms}
                        onChange={(data) => setInputRooms(data)}
                        className="flex-[1.2]"
                    />
                </form>
            </>
        );
    };

    return renderForm();
};

export default BookingForm;
