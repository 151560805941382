import React from "react";
import { useTranslation } from "react-i18next";

export type translateFunction = (
    key: string,
    params?: Record<string, unknown>,
    defaultValue?: string
) => string;

export default function useTranslate(): translateFunction {
    const { t } = useTranslation();

    return React.useCallback(
        (key, params?, defaultValue?) => t(key, defaultValue, params),
        [t]
    );
}
