export const LANG_ID_MAP: any = {
    es: 1,
    en: 2,
    fr: 3,
    it: 4,
    ru: 5
}

// I recommend using this tool to generate the colors:
// https://materialpalettes.com/
export const CUSTOMER_THEMES: any = {
    // PokerStars
    2: {
        primaryColor: {
            "50": "#fbe8e7",
            "100": "#ffcabc",
            "200": "#ffa891",
            "300": "#ff8565",
            "400": "#ff6944",
            "500": "#ff4e25",
            "600": "#f44821",
            "700": "#e6411c",
            "800": "#d83a17",
            "900": "#bf2c0e"
        },
        secondaryColor: {
            "50": "#f5f5f5",
            "100": "#e9e9e9",
            "200": "#d9d9d9",
            "300": "#c4c4c4",
            "400": "#9d9d9d",
            "500": "#7b7b7b",
            "600": "#555555",
            "700": "#434343",
            "800": "#262626",
            "900": "#000000"
        },
    },
    // Formula-e
    4: {
        primaryColor: {
            "50": "#f5f5f5",
            "100": "#e9e9e9",
            "200": "#d9d9d9",
            "300": "#c4c4c4",
            "400": "#9d9d9d",
            "500": "#7b7b7b",
            "600": "#555555",
            "700": "#434343",
            "800": "#262626",
            "900": "#000000"
        },
        secondaryColor: {
            "50": "#f0e6ff",
            "100": "#d5c2ff",
            "200": "#b798ff",
            "300": "#966bff",
            "400": "#7745ff",
            "500": "#5013ff",
            "600": "#3b0eff",
            "700": "#0000ff",
            "800": "#0000fb",
            "900": "#0000f9"
        },
    },
}
